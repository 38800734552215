
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetPartnerDetails } from '../../../api/programApi'; 


export const fetchPartnerDetails = createAsyncThunk(
  'partner/fetchPartnerDetails',
  async () => {
    const campaign = localStorage.getItem("campaign") || "Default";
    const partnerData = await GetPartnerDetails(campaign);

    return partnerData?.[0]?.partner_details?.partner_logo || [];
  }
);

const partnerSlice = createSlice({
  name: 'partner',
  initialState: {
    partnerLogo: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPartnerDetails.fulfilled, (state, action) => {
        state.partnerLogo = action.payload;
        state.loading = false;
      })
      .addCase(fetchPartnerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectPartnerLogo = (state) => state.partner.partnerLogo;

export default partnerSlice.reducer;

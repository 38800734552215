import "./tool.css";
import React,{useState} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { formatNumberWithCommasAndMillions,handlePercentageChange,stripPercentage } from "../../../../../utils/helper";
import {calculateProgram2Toolkit4} from "../../../../../api/programApi";

const Program2Toolkit4 = ({ data }) => {

    const [yearOverForecast, setYearOverForecast] = useState("0.0");
    const [cogsSalesForecast, setCogsSalesForecast] = useState("86.7");
    const [calculatedValue, setCalculatedValue] = useState(null);
  
    const handleBlur = async () => {
      try {
        const yearOverForecastValue = stripPercentage(yearOverForecast);
        const cogsSalesForecastValue = stripPercentage(cogsSalesForecast);
        const response = await calculateProgram2Toolkit4(yearOverForecastValue,cogsSalesForecastValue);
        
        setCalculatedValue({
            sales_forecast: response?.sales_forecast,
            cog_sold_forecast: response?.cog_sold_forecast,
            gross_profit_forecast: response?.gross_profit_forecast,
            gross_margin_forecast: response?.gross_margin_forecast,
          });
      } catch (error) {
        console.error("Error calculating forecast", error);
      }
    };
 
  const userName = useSelector((state) => state.userSlice.name);

  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    return field ? field.field_value : "";
  };

  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        {/* Header Row */}
        <h3 className="background-blue font-white tool-header">
          <i className="fas fa-calculator"></i> {getField("toolheader_header")}
        </h3>

        {/* Combined Table */}
        <table className="tool-table">
          <thead>
            <tr>
              <th>{getField("name")}</th>
              <th>{getField("symbol")}</th>
              <th>{getField("latest")}</th>
              <th>{getField("next_report")}</th>
            </tr>
          </thead>
          <tbody>
            {/* Initial Rows */}
            <tr>
              <td className="td-tool-value">{getField("company_name")}</td>
              <td className="td-tool-value">{getField("symbol_name")}</td>
              <td className="td-tool-value">{getField("latest_value")}</td>
              <td className="td-tool-value">{getField("next_report_value")}</td>
            </tr>

            {/* Analyst Information */}
            <tr>
              <th>{getField("analyst")}</th>
              <th>{getField("shares")}</th>
              <th>{getField("market_px")}</th>
              <th>{getField("market_cap")}</th>
            </tr>
            <tr>
              <td className="exchange-rate-result">{userName}</td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(getField("shares_value")))}</td>
              <td className="td-tool-value">${getField("market_px_value")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("market_cap_value")))}</td>
            </tr>

            {/* Section Header */}
            <tr>
              <td colSpan="4" className="toolkit-full-row-header">
                {getField("lesson_header")}
              </td>
            </tr>

            {/* Column Headers */}
            <tr>
              <th></th>
              <th>{getField("year")}</th>
              <th>{getField("baseline")}</th>
              <th>{getField("forecast")}</th>
            </tr>

            {/* Additional Rows */}
            <tr>
              <td>{getField("quarterly")}</td>
              <td className="td-tool-value">{getField("year_value")}</td>
              <td className="td-tool-value">{getField("baseline_value")}</td>
              <td className="td-tool-value">{getField("forecast_value")}</td>
            </tr>
           
            <tr>
              <td>{getField("sales")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("sales_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("sales_baseline")))}</td>
              <td className="exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedValue?.sales_forecast)}</td>
            </tr>
            <tr>
              <td>{getField("year_over")}</td>
              <td></td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(getField("year_over_baseline")))}%</td>
              <td><input
                className="form-input"
                type="text"
                value={yearOverForecast}
                onChange={handlePercentageChange(setYearOverForecast)}
                onBlur={handleBlur}
                />
              </td>
            </tr>

            <tr>
              <td>{getField("cog_sold")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cog_sold_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cog_sold_baseline")))}</td>
              <td className="exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedValue?.cog_sold_forecast)}</td>
            </tr>
            <tr>
              <td>{getField("cogs_sales")}</td>
              <td className="td-tool-value">{getField("cogs_sales_year")}%</td>
              <td className="td-tool-value">{getField("cogs_sales_baseline")}%</td>
              <td ><input
                className="form-input"
                type="text"
                value={cogsSalesForecast}
                onChange={handlePercentageChange(setCogsSalesForecast)}
                onBlur={handleBlur}
                />
              </td>
            </tr>
            <tr>
              <td>{getField("gross_profit")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("gross_profit_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("gross_profit_baseline")))}</td>
              <td className="exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedValue?.gross_profit_forecast)}</td>
            </tr>
            <tr>
              <td>{getField("gross_margin")}</td>
              <td className="td-tool-value">{getField("gross_margin_year")}%</td>
              <td className="td-tool-value">{getField("gross_margin_baseline")}%</td>
              <td className="exchange-rate-result" >{calculatedValue?.gross_margin_forecast !== undefined ? `${calculatedValue?.gross_margin_forecast}%` : ""}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Program2Toolkit4.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Program2Toolkit4;

import React,{useState} from "react";
import PropTypes from "prop-types";
import {formatNumberWithCommasAndMillions, handlePercentageChange,stripPercentage } from "../../../../../utils/helper";
import {CalculatePMOSToolkit1} from "../../../../../api/programApi";
import { ToolkitImageLogo } from "../../../../imagepath";
import "./tool.css";

const PMOSToolkit1 = ({ data }) => {

  const [investmentCommitment, setInvestmentCommitment] = useState("0%");
  const [calculatedValue, setCalculatedValue] = useState(null);


  const handleBlur = async () => {
    try {
      const investmentCommitmentValue = stripPercentage(investmentCommitment);
      
      const response = await CalculatePMOSToolkit1(investmentCommitmentValue);

      setCalculatedValue({
        investment_commitment_dollars:response?.investment_commitment_dollars,
        variable_expenses_total_expenses:response?.variable_expenses_total_expenses,
        total_expenses_value:response?.total_expenses_value,
        variable_expenses_monthly_profit:response?.variable_expenses_monthly_profit,
        monthly_profit_value:response?.monthly_profit_value,
        });

        localStorage.setItem(
          "investment_commitment_dollars",
          response?.investment_commitment_dollars
        );

        localStorage.setItem(
          "variable_expenses_total_expenses",
          response?.variable_expenses_total_expenses
        );
        
    } catch (error) {
      console.error("Error calculating PMOSToolkit1 value", error);
    }
  };

  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    return field ? field.field_value : "";
  };

  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        {/* Header Row */}
        {/* <h3 className="tool-header">
          <i className="fas fa-calculator"></i> PMoS Forecaster
        </h3> */}

        {/* Table Structure */}
        <table className="tool-table">
          <thead>
            <tr>
              <th className="background-color-white"><img 
                src={ToolkitImageLogo}
                className="img-fluid"
                alt="Logo" 
              /></th>
              
              <th className="background-color-white" colSpan="2"><h4>{getField("lesson_header")}</h4></th>
            </tr>
          </thead>
          <tbody>
            {/* Name and Age */}
            <tr>
              <th>{getField("name")}</th>
              <th></th>
              <th>{getField("age")}</th>
            </tr>
            <tr>
              <td className="td-tool-value">{getField("name_value")}</td>
              <td className="td-tool-value"></td>
              <td className="td-tool-value">{getField("age_value")}</td>
            </tr>

            {/* PMoS Details */}
            <tr>
              <td colSpan="3" className="background-blue font-white">
                <b>      
                {getField("pmos_details")}
                </b> 
              </td>
            </tr>

            
            <tr>
              <td>{getField("total_income")}</td>
              <td></td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("total_income_value")))}</td>
            </tr>

            <tr>
              <td>{getField("investment_commitment")}</td>
              <td ><input
                className="form-input"
                type="text"
                value={investmentCommitment}
                onChange={handlePercentageChange(setInvestmentCommitment)}
                onBlur={handleBlur}
                />
              </td>
              <td className="exchange-rate-result">{calculatedValue?.investment_commitment_dollars !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.investment_commitment_dollars)}` : ""}
              </td>
            </tr>


           
            <tr>
              <td>{getField("debt_payment")}</td>
              <td className="td-tool-value">{getField("debt_payment_value")}%</td>
              <td className="td-tool-value">${getField("debt_payment_dollars")}</td>
            </tr>

            <tr>
              <td>{getField("total_fixed")}</td>
              <td className="td-tool-value">{getField("total_fixed_value")}%</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("fixed_expenses_total_fixed")))}</td>
            </tr>

            <tr>
              <td>{getField("total_variable")}</td>
              <td className="td-tool-value">{getField("total_variable_value")}%</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("variable_expenses_total_variable")))}</td>
            </tr>
              <tr>
                {/* <td></td> */}
                <td colSpan="3" className="background-blue font-white"></td>
              </tr>
             {/* Summary */}
             <tr>
              <td>{getField("total_expenses")}</td>
              <td className="exchange-rate-result">{calculatedValue?.total_expenses_value !== undefined ? `${calculatedValue?.total_expenses_value}%` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.variable_expenses_total_expenses !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.variable_expenses_total_expenses)}` : ""}
              </td>
            </tr>
            <tr>
              <td>Monthly Profit</td>
              <td className="exchange-rate-result">{calculatedValue?.monthly_profit_value !== undefined ? `${calculatedValue?.monthly_profit_value}%` : ""}</td>
              <td className="exchange-rate-result">	{calculatedValue?.variable_expenses_monthly_profit !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.variable_expenses_monthly_profit)}` : ""}
              </td>
            </tr>


            <tr>
              {/* <td colSpan="3" className="black-background font-white"><b>  {getField("details")}</b></td> */}
              <td colSpan="3" className="background-blue font-white"><b>  {getField("details")}</b></td>


            </tr>
            <tr>
              <td colSpan="3"   className="background-deep-blue font-white">{getField("total_income")}</td>


            </tr>
            <tr>
              <td>{getField("pharmacy")}</td>
              <td className="td-tool-value"></td>
              <td className="td-tool-value">${getField("pharmacy_dollars")}</td>
            </tr>
            <tr>
              <td>{getField("income_two")}</td>
              <td className="td-tool-value"></td>
              <td className="td-tool-value">${getField("income_two_dollars")}</td>
            </tr>
            <tr>
              <td>{getField("income_three")}</td>
              <td className="td-tool-value"></td>
              <td className="td-tool-value">${getField("income_three_dolars")}</td>
            </tr>


            {/* Fixed Expenses */}
            <tr>
              <td   colSpan="3" className="background-deep-blue font-white">
                {getField("total_fixed")}
              </td>

            </tr>
            <tr>
              <td>{getField("rent")}</td>
              <td className="td-tool-value">{getField("rent_value")}%</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("fixed_expenses_rent")))}</td>
            </tr>
            <tr>
              <td>{getField("utilities")}</td>
              <td className="td-tool-value">{getField("utilities_value")}%</td>
              <td className="td-tool-value">${getField("fixed_expenses_utilities")}</td>
            </tr>
            <tr>
              <td>{getField("phone")}</td>
              <td className="td-tool-value">{getField("phone_value")}%</td>
              <td className="td-tool-value">${getField("fixed_expenses_phone")}</td>
            </tr>
            <tr>
              <td>{getField("car")}</td>
              <td className="td-tool-value">{getField("car_value")}%</td>
              <td className="td-tool-value">${getField("fixed_expenses_car")}</td>
            </tr>
            <tr>
              <td>{getField("monthly")}</td>
              <td className="td-tool-value">{getField("monthly_value")}</td>
              <td className="td-tool-value">${getField("fixed_expenses_monthly")}</td>
            </tr>


            {/* Variable Expenses */}
         
             <tr>
              <td  colSpan="3" className="background-deep-blue font-white">
               {getField("variable_expenses")}
              </td>


            </tr>
            <tr>
              <td>{getField("groceries")}</td>
              <td className="td-tool-value">{getField("groceries_value")}%</td>
              <td className="td-tool-value">${getField("variable_expenses_groceries")}</td>
            </tr>
            <tr>
              <td>{getField("eating")}</td>
              <td className="td-tool-value">{getField("eating_value")}%</td>
              <td className="td-tool-value">${getField("variable_expenses_eating")}</td>
            </tr>
            <tr>
              <td>{getField("travel")}</td>
              <td className="td-tool-value">{getField("travel_value")}%</td>
              <td className="td-tool-value">${getField("variable_expenses_travel")}</td>
            </tr>
            <tr>
              <td>{getField("entertainment")}</td>
              <td className="td-tool-value">{getField("entertainment_value")}%</td>
              <td className="td-tool-value">${getField("variable_expenses_entertainment")}</td>
            </tr>
            <tr>
              <td>{getField("health")}</td>
              <td className="td-tool-value">{getField("health_value")}%</td>
              <td className="td-tool-value">${getField("variable_expenses_health")}</td>
            </tr>


           
          </tbody>
        </table>
      </div>
    </div>
  );
};

PMOSToolkit1.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default PMOSToolkit1;
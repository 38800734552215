import React, { useState, useEffect } from "react";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import { useTranslation } from "react-i18next";

const StudentSupport = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: "",
    question: "",
    screenshot: null,
  });

  useEffect(() => {
    const storedEmail = localStorage.getItem("Email");
    if (storedEmail) {
      setFormData((prevData) => ({ ...prevData, email: storedEmail }));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, screenshot: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Data:", formData);

    // API integration logic will go here
    alert("Your query has been submitted.");
  };

  return (
    <>
      {/* Main Wrapper */}
      <div className="main-wrapper">
        {/* Header */}
        <StudentHeader activeMenu={"Support"} />
        {/* /Header */}
        {/* Breadcrumb */}
        {/* <div className="breadcrumb-bar breadcrumb-bar-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">{t("support")}</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/student/student-dashboard">{t("home")}</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        {t("support")}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="page-content bg-light-grayish-blue">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              <StudentSidebar />
              {/* /Sidebar */}
              {/* Support Form */}
              <div className="col-xl-9 col-lg-9 container-with-margin" style={{marginTop:"60px"}}>
                <div className="settings-widget mb-0">
                  <div className="settings-menu p-10">
                    <div className="profile-heading">
                      <h3>{t("support")}</h3>
                    </div>
                    <form onSubmit={handleSubmit} className="personal-address">
                      <div className="row">
                        {/* Email */}
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="email">{t("email")}</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control read-only"
                            value={formData.email}
                            onChange={handleInputChange}
                            readOnly={!!formData.email}
                          />
                        </div>
                        {/* Question */}
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="question">{t("describeYourIssue")}</label>
                          <textarea
                            id="question"
                            name="question"
                            rows="4"
                            className="form-control"
                            placeholder={t("enterYourQuestion")}
                            value={formData.question}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                        </div>
                        {/* Screenshot */}
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="screenshot">{t("uploadScreenshot")}</label>
                          <input
                            type="file"
                            id="screenshot"
                            name="screenshot"
                            className="form-control"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        </div>
                        {/* Submit Button */}
                        <div className="col-sm-12 text-center">
                          <button type="submit" className="btn btn-primary">
                            {t("submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /Support Form */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {/* /Main Wrapper */}
    </>
  );
};

export default StudentSupport;

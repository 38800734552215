import React from "react";
import Footer from "../../footer";
import PageHeader from "../header";
import { useTranslation } from "react-i18next";

const TermsCondition = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="main-wrapper">
        <PageHeader />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {t('termsAndConditionsHeader')}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h1 className="mb-0">{t('termsAndConditionsHeader')}</h1>
                    </div>
                </div>
            </div>
        </div>

        <div className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="terms-content">
                            <div className="terms-text">
                                <p>
                                  {t('termsAndConditionsP1')}
                                </p>
                                <p>
                                  {t('termsAndConditionsP2')}
                                </p>
                                <p>
                                  {t('termsAndConditionsP3')}
                                </p>
                                <p>
                                  {t('termsAndConditionsP4')}
                                </p>
                                <p>
                                  {t('termsAndConditionsP5')}
                                </p>
                                <p>
                                  {t('termsAndConditionsP6')}
                                </p>
                            </div>
                            <div className="terms-text">
                                <p>
                                  {t('termsAndConditionsP7')}
                                </p>
                                <p>
                                  {t('termsAndConditionsP8')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default TermsCondition;

import React,{useState,useEffect} from 'react'
// import Footer from '../../footer'
import StudentHeader from '../header'
import StudentSidebar from '../sidebar'
import { getStudentDetails } from '../../../api/studentApi';
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";


const StudentProfile = () => {
    const { t } = useTranslation();
    const [studentData, setStudentData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        contact_no: '',
        language: '',
    });

    useEffect(() => {
        const email = localStorage.getItem("Email");
        const storedStudentDetails = localStorage.getItem("studentDetails");

        const fetchStudentData = async () => {
            if (storedStudentDetails) {
                // If student details are in localStorage, use them
                const parsedDetails = JSON.parse(storedStudentDetails);
                setStudentData({
                    first_name: parsedDetails.first_name,
                    last_name: parsedDetails.last_name,
                    email: parsedDetails.email,
                    contact_no: parsedDetails.contact_no,
                    language: parsedDetails.language,
                });
            } else if (email) {
                // If not in localStorage, fetch data from API
                const response = await getStudentDetails(email);
                
                if (response?.data?.data[0]) {
                    const data = response.data.data[0];
                    setStudentData({
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        contact_no: data.contact_no,
                        language: data.language,
                    });
                    // Store fetched details in localStorage for future use
                    localStorage.setItem("studentDetails", JSON.stringify(data));
                }
            }
        };

        fetchStudentData();
    }, []);
    return (
        <>
            {/* Main Wrapper */}
            <div className="main-wrapper">
                {/* Header */}
                <StudentHeader activeMenu={"My Profile"} />
                {/* /Header */}
                {/* Breadcrumb */}
                {/* <div className="breadcrumb-bar breadcrumb-bar-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <h2 className="breadcrumb-title">{t('myProfile')}</h2>
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/student/student-dashboard">{t('home')}</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {t('myProfile')}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* /Breadcrumb */}
                {/* Page Content */}
                <div className="page-content bg-light-grayish-blue"  >
                    <div className="container">
                        <div className="row">
                            {/* sidebar */}
                           <StudentSidebar/>
                            {/* /Sidebar */}
                            {/* Student Profile */}
                            <div className="col-xl-9 col-lg-9 container-with-margin" style={{marginTop:"60px"}}>
                                <div className="settings-widget card-details mb-0">
                                    <div className="settings-menu p-0">
                                        <div className="profile-heading">
                                            <h3>{t('myProfile')}</h3>
                                        </div>
                                        <div className="checkout-form personal-address">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="contact-info">
                                                        <h6>{t('firstName')}</h6>
                                                        <p>{studentData.first_name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="contact-info">
                                                        <h6>{t('lastName')}</h6>
                                                        <p>{studentData.last_name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="contact-info">
                                                        <h6>{t('email')}</h6>
                                                        <p>{studentData.email}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="contact-info">
                                                        <h6>{t('contactNumber')}</h6>
                                                        <p>{studentData.contact_no}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="contact-info">
                                                        <h6>{t('language')}</h6>
                                                        <p>{studentData.language}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Student Profile */}
                        </div>
                    </div>
                </div>
                {/* /Page Content */}
                {/* Footer */}
               {/* <Footer/> */}
                {/* /Footer */}
            </div>
            {/* /Main Wrapper */}
        </>

    )
}

export default StudentProfile

import axios from "axios";
import { encode as base64_encode} from 'base-64';

// Retry interceptor function
import axiosRetry from 'axios-retry';

// Pass the axios instance to the retry function  and call it
axiosRetry(axios, { 
  retries: 2, // Number of retries
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
  retryCondition(error) {
    // Conditional check the error status code
    switch (error.response.status) {
      case 500:
        return true; // Retry request with response status code 500
      case 400:
        return true; // Retry request with response status code 400
      default:
        return false; // Do not retry the others
    }
  },
});

const url = process.env.REACT_APP_API_BASE_URL;
const tenantName = process.env.REACT_APP_TENANT_NAME;
export const registerStudent = async (studentData) => {
  
    const payload = {
        body: {
            request: { ...studentData },
            response: null,
        },
        tenant_name: tenantName,
    };


    try {
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `b9018ad2-13b1-4603-8c87-535c5c6ce4bf`,
        },
      });
        return response.data;
    } catch (error) {
        console.error("Error registering student:", error);
        throw error;
    }
};


export const getStudentDetails = async (email) => {
 
    const payload = {
        body: {
            student_email: base64_encode(email),
        },
        tenant_name: tenantName,
    };

    try {
       
        const response = await axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': `a57dbd27-09fa-4c0f-9b5c-f56b0ffcd574`,
          },
        });
        return response.data;
    } catch (error) {
        console.error("Error getting student details:", error);
        throw error;
    }
};


export const updateStudentDetails = async (updateData) => {
  
    
    const payload = {
        body: {
            request: { ...updateData },
            response: null,
        },
        tenant_name: tenantName,
    };

    try {
       
        const response = await axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': `a4882414-dcf7-4f43-b60f-257965e17c7d`,
          },
        });
        return response.data;
    } catch (error) {
        console.error("Error updating student details:", error);
        throw error;
    }
};


export const updateStudentPassword = async (updateData) => {
  
  const payload = {
      body: {
          request: { ...updateData },
          response: null,
      },
      tenant_name: tenantName,
  };

  try {
     
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `4fcd1192-1996-4474-bdd8-0257d180f019`,
        },
      });
      return response.data;
  } catch (error) {
      console.error("Error updating password:", error);
      throw error;
  }
};

export const getQuizById = async (quizId=1) => {
  
    const requestBody = {
      body: {
        request: {
          quiz_id: quizId,
        },
        response: null,
        srno: 0,
      },
      tenant_name: tenantName,
    };
  
    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `ad460ada-bcaf-4625-967d-9b82ee30d269`,
        },
      });
     
      if (response.data.success) {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Failed to fetch quiz');
      }
    } catch (error) {
      console.error('Error fetching quiz:', error);
      return null;
    }
  }

  export const saveQuizResults = async (quizRequest) => {
    
    
    const requestData = {
      body: {
        request: quizRequest,
        response: null
      },
      tenant_name: tenantName
    };
   
    try {
      const response = await axios.post(
        url,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key':'a36c3f8f-816b-4cb0-999b-01f687ca344a',
          }
        }
      );
      
      
      return response.data; 
    } catch (error) {
      console.error("Error saving quiz results:", error);
      throw error;  
    }
  };

export const fetchQuestionById = async (questionId) => {
    
    const requestData = {
      body: {
        response: null,
        request: {
          question_id: questionId,
        },
      },
      tenant_name: tenantName,
    };
    try {
      const response = await axios.post(url, requestData,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key':'84f629ef-3a8b-4487-bc48-5c0269dd7ec5',
        }
      });
      if (response.data.success) {
        return response.data.data[0];
      } else {
        console.error("Failed to fetch question data:", response.data.message);
      }
    } catch (error) {
      console.error("Error while fetching question data:", error);
    }
  };

export const getQuizByType = async (quizType,language, userEmail) => {
    
    const payload = {
        body: {
          language: language, 
          quiz_type: quizType,
          user_email: userEmail 
        },
        tenant_name: tenantName
      }
      
    try {
      const response = await axios.post(url, payload,{
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': `abb8e068-924d-465a-89a0-f6d4aedf0649`,
            },
          });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching quiz by type:', error);
      throw error;
    }
  };
  

  export const saveOnBoardingQuiz = async (quizResults, quizSubmission) => {
    
    const payload = {
      body: {
        request: {
          profile_quiz_results: quizResults,
          quiz_submissions: quizSubmission
        }
      },
      tenant_name: tenantName
    }
    try {
      const response = await axios.post(url, payload,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `35da56e6-b06c-40bb-866b-dc0eb4cb0116`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error saving OnBoarding quiz:', error);
      throw error;
    }
  };
  

  export const getProfileQuizSubmissionByEmail = async (email) => {
    
    const body = {
      body: {
        student_email: email,
      },
      tenant_name: tenantName,
    };
  
    try {
      const response = await axios.post(url, body,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `029f6b43-42f2-47d2-b07a-df1a59ecdf12`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching quiz submission:", error);
      return null;
    }
  };
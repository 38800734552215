import React from "react";
import CourseHeader from "../header";
import DetailsContent from "./detailsContent";
import { Icon1,  Timer } from "../../../imagepath";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {getUserLessonsStatus} from "../../../../api/programApi";

const CourseDetails = () => {
  const location = useLocation();
 
  const trainingProgram = location.state?.trainingProgram || JSON.parse(localStorage.getItem('trainingProgram'));
  const percentCompletion = location.state?.percentCompletion || 0;  
  
   if (location.state?.trainingProgram) {
    localStorage.setItem('trainingProgram', JSON.stringify(location.state.trainingProgram));
  }

  if (!trainingProgram) {
    
    return <div>Training Program details not found.</div>;
  }
 

  const fetchLessonStatuses = async (openCourse) => {
    const email = localStorage.getItem("Email");
    const userEmail = email; 
    const trainingId = trainingProgram.srno;
    const courseId = openCourse; // Only fetch status for the currently open course
    
    if (courseId) {
      try {
        const statusData = await getUserLessonsStatus(userEmail, trainingId, courseId);
        if (statusData?.success) {
         
          return statusData.data; // Store the lesson statuses
        }
      } catch (error) {
        console.error("Error fetching lesson status:", error);
      }
    }
  };
  return (
    <>
      <div className="main-wrapper">
        <CourseHeader activeMenu={"CourseDetails"}/>

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {trainingProgram?.program_header}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="inner-banner"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0">
                  <div className="about-instructor align-items-center">
                    <div className="abt-instructor-img">
                      <Link to="/student/student-profile">
                        <img
                          src={trainingProgram && trainingProgram?.logo[0]}
                          alt="img"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="instructor-detail me-3">
                      {/* <h5>
                        <Link to="/instructor/instructor-profile">Nicole Brown</Link>
                      </h5> */}
                       <h2>{trainingProgram && trainingProgram?.program_header}</h2>
                      {/* <p>UX/UI Designer</p> */}
                    </div>
                    {/* <div className="rating mb-0">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        <span>4.5</span> (15)
                      </span>
                    </div> */}
                  </div>
                  <div className="mb-3 d-flex flex-wrap">
                    {trainingProgram && trainingProgram?.tags?.split(",").map((tag, index) => (
                        <span key={index} className="badge text-bg-secondary me-2 fs-5">{tag}</span>
                      ))}
                  </div>
                  
                </div>
                {/* <h2>The Complete Web Developer Course 2.0</h2> */}
                <p>
                {trainingProgram && trainingProgram?.details}
                </p>
                <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
                  <div className="cou-info">
                    <img src={Icon1} alt="" />
                    <p>{trainingProgram && trainingProgram?.number_of_lessons} Lesson</p>
                  </div>
                  <div className="cou-info">
                    <img src={Timer} alt="" />
                    <p>{trainingProgram && trainingProgram?.duration}</p>
                  </div>
                  {/* <div className="cou-info">
                    <img src={People} alt="" />
                    <p>32 students enrolled</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <DetailsContent trainingProgram={trainingProgram}  percentCompletion={percentCompletion} fetchLessonStatuses={fetchLessonStatuses} />

        {/* <Footer/> */}

      </div>
    </>
  );
};

export default CourseDetails;





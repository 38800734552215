
import React from "react";
import { useTranslation } from "react-i18next";
import { academy ,barGroup,pieGroup} from "../../imagepath";
import { Link } from "react-router-dom";

const TopCategory = () => {
  const { t } = useTranslation();

  return (
    <section className="section top-category d-flex align-items-center">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Side: Image */}
          <div className="col-md-6 custom-background position-relative">
            <div className="academy-container position-relative">
            {/* Background small images */}
            <img src={barGroup} alt="" className="background-small-image barGroup" />
            <img src={pieGroup} alt="" className="background-small-image pieGroup" />

            <img src={academy} alt="" className="img-fluid barGraph-image position-relative" />
            </div>
          </div>

          {/* Right Side: Text Content */}
          <div className="col-md-6 text-left">
            <h2 style={{ fontFamily: "Helvetica", fontWeight: 500 }}>
              {t("onlineLearningAcademy")}
            </h2>
            <p >
              {t("traditionalEducationIssue")}
            </p>
            <Link className="btn btn-action-blue my-3 bg-ivot-primary text-white box-shadow" style={{width: '70%' }} to="/login">
                  {t("getStarted")}
                </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopCategory;


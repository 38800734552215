import React from "react";
import { useTranslation } from "react-i18next";
import { messageSent, internetLearning, equality,piggyGroup,pieTwoGroup,dollarOneGroup,dollarTwoGroup,financialPlanGroup,barChartGroup} from "../../imagepath";

const WhyIVoT = () => {
  const { t } = useTranslation();

  return (
    <section className="section why-ivot">
       
      <div className="container">
        <div className="section-header text-center">
          <h2>{t("whyChooseIVoT")}</h2>
        </div>
        <div className="row position-relative">
        <img src={dollarOneGroup} alt="" className="background-small-image dollarOneGroup" />
        <img src={dollarTwoGroup} alt="" className="background-small-image dollarTwoGroup" />
        <img src={pieTwoGroup} alt="" className="background-small-image pieTwoGroup" />
        <img src={financialPlanGroup} alt="" className="background-small-image financialPlanGroup" />
        <img src={barChartGroup} alt="" className="background-small-image barChartGroup" />
        <img src={piggyGroup} alt="" className="background-small-image piggyGroup" />
        <div className="col-lg-4 col-md-6 mb-4" >
              <div className="why-ivot-card text-center p-4 position-relative">

                 {/* Card Content */}
                <img src={messageSent} alt="" className="img-fluid mb-3" />
                <div className="why-ivot-card-content">

                <h4 className="mb-2">{t("realWorldApplication")}</h4>
                <p>{t("financialLiteracy")}</p>
                </div>
              </div>
            </div>
        <div className="col-lg-4 col-md-6 mb-4" >
              <div className="why-ivot-card text-center p-4 position-relative">

                
            
                <img src={internetLearning} alt="" className="img-fluid mb-3" />
                <div className="why-ivot-card-content">

                <h4 className="mb-2">{t("internetBasedLearning")}</h4>
                <p>{t("customizableLearning")}</p>
                </div>
              </div>
            </div>
        <div className="col-lg-4 col-md-6 mb-4" >
              <div className="why-ivot-card text-center p-4 position-relative">

                
                 {/* Card Content */}
                <img src={equality} alt="" className="img-fluid mb-3" />
                <div className="why-ivot-card-content">

                <h4 className="mb-2">{t("inclusiveAccessibility")}</h4>
                <p>{t("inclusiveCommunity")}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default WhyIVoT;

import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './sidebarSlice';
import userReducer from './userSlice';
import trainingReducer from './trainingSlice'; 
import partnerReducer from './partnerSlice';


const store = configureStore({
  reducer: {
    sidebarSlice: sidebarReducer,
    userSlice:userReducer,
    training: trainingReducer,
    partner: partnerReducer,
  },
});

export default store;

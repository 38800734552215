import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";


const CourseOverview = ({trainingProgram}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="card overview-sec">
                <div className="card-body">
                  <h5 className="subs-title">{t('programTitle')}</h5>
                  <div className="content-container" dangerouslySetInnerHTML={{ __html: trainingProgram?.program_overview }} />
                </div>
      </div>
    </>
  );
};

CourseOverview.propTypes = {
  trainingProgram: PropTypes.shape({
    program_overview: PropTypes.string.isRequired, 
  }).isRequired, 
};
export default CourseOverview;




import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  getQuizById } from "../../../../api/studentApi";
import {createUserEvent ,getPrintTemplate} from "../../../../api/programApi"
import LessonQuiz from "./LessonQuiz";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';



const LessonContent = ({ lesson,trainingProgram, isLastLesson, onTrainingCompleted ,onBackToProgramContent,fetchLessonStatuses,openedCourse}) => {
  const { t } = useTranslation();
  const [printTemplate, setPrintTemplate] = useState(null);
  const [quizData, setQuizData] = useState(null); // For quiz lessons
  
  const navigate = useNavigate();
  
  const navigateToCourse = () => {
    onBackToProgramContent()
    navigate('/course-details');
    }
   
  
  const handleLessonCompleted = async () => {
    const eventData = {
      eventType: "Lesson Completed",
      userEmail: localStorage.getItem("Email"),  
      trainingId: trainingProgram.srno,
      courseId: lesson.course_id[0],
      lessonId: lesson.srno,
    };
    
    try {
      const response = await createUserEvent(
        eventData.eventType,
        eventData.userEmail,
        eventData.trainingId,
        eventData.courseId,
        eventData.lessonId
      );
      console.log("Lesson Completed event tracked successfully", response);
      fetchLessonStatuses(openedCourse)
       // After lesson completion is successfully tracked
      //  onBackToProgramContent(); 
       navigateToCourse(); 
    } catch (error) {
      console.error("Error tracking Lesson Completed event", error);
    }
  };
  

  
  const fetchPrintTemplate = async () => {
    if (lesson.lesson_template?.[0]) {
      const templateData = await getPrintTemplate(lesson.lesson_template[0]);
      setPrintTemplate(templateData || "<p>No content available</p>");
    }
  };

 
  const fetchQuizData = async (quizId) => {
    const quizData = await getQuizById(quizId);
    setQuizData(quizData);
  };
  
  useEffect(() => {
    if (lesson) {
      const userEmail = localStorage.getItem("Email"); 
      const trainingId = trainingProgram.srno;
      const lessonId = lesson.srno; 
      const courseId = lesson.course_id[0]; 

      if (userEmail && trainingId && lessonId && courseId) {
        createUserEvent("Lesson Started", userEmail, trainingId, courseId, lessonId)
          .then((response) => {
            console.log("User event Lesson Started created successfully:", response);
          })
          .catch((error) => {
            console.error("Error creating user event:", error);
          });
      }
    }
  }, []); 

  useEffect(() => {
    if (lesson) {
      if (lesson.lesson_type === "Content") {
        fetchPrintTemplate(); 
      } else if (lesson.lesson_type === "Quiz" && lesson.lesson_quiz_id.length > 0) {
        fetchQuizData(lesson.lesson_quiz_id[0]); 
      }
    }
  }, [lesson]);


  return (
    <div>
      
      {lesson && lesson.lesson_type === "Content" && (
  <>
        {lesson?.lesson_content ? (
          <div
            className="content-container" 
            dangerouslySetInnerHTML={{
              __html: lesson?.lesson_content,
            }}
          />
        ) : (
          printTemplate && (
            <div
              className="content-container" 
              dangerouslySetInnerHTML={{
                __html: printTemplate,
              }}
            />
          )
        )}

      
        <div style={{ marginTop: '20px' }}>
          <button className="btn btn-primary" onClick={handleLessonCompleted}>
            {t('markLessonAsCompleted')}
          </button>
        
          {isLastLesson && lesson.lesson_type === 'Content' && (
                <button className="btn btn-success ms-2" onClick={onTrainingCompleted}>{t('completeTrainingProgram')}</button>
            )}
        </div>
      </>
    )}


      
      {lesson && lesson.lesson_type === "Quiz" && quizData && (
        <LessonQuiz quizData={quizData} handleLessonCompleted={handleLessonCompleted} isLastLesson={isLastLesson} onTrainingCompleted={onTrainingCompleted} onBackToProgramContent={onBackToProgramContent} trainingId={trainingProgram.srno}  />
      )}

      
      {lesson && lesson.lesson_type !== "Content" && lesson.lesson_type !== "Quiz" && (
        <p>{t('unknownLessonType')}</p>
      )}
    </div>
  );
};



LessonContent.propTypes = {
  lesson: PropTypes.shape({
    lesson_type: PropTypes.string.isRequired, 
    lesson_quiz_id: PropTypes.arrayOf(PropTypes.string), 
    course_id: PropTypes.arrayOf(PropTypes.number).isRequired, 
    srno: PropTypes.number.isRequired, 
    lesson_template: PropTypes.arrayOf(PropTypes.string).isRequired,
    lesson_content: PropTypes.string,
  }).isRequired,
  trainingProgram: PropTypes.shape({
    srno: PropTypes.number.isRequired, 
  }).isRequired,
  isLastLesson: PropTypes.bool.isRequired, 
  onTrainingCompleted: PropTypes.func.isRequired, 
  onBackToProgramContent: PropTypes.func.isRequired, 
  fetchLessonStatuses: PropTypes.func.isRequired, 
  openedCourse:PropTypes.number.isRequired,
};

export default LessonContent;




import React,{useState} from "react";
import { Link } from "react-router-dom";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import StudentSettingPageHeader from "./settingPageHeader";
import { useTranslation } from "react-i18next";
import {updateStudentPassword} from "../../../api/studentApi";
import {handleResponse} from "../../../api/programApi";

const StudentChangePassword = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const email = localStorage.getItem("Email");

  const [studentData, setStudentData] = useState({
    old_password: "",
    new_password: "",
    new_password1: "",
    user_email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentData({ ...studentData, [name]: value });
  };

  // Handle update password
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if(loading){
      return;
    }
    setLoading(true);
    setError(null);
    setMessage(null);
    try {
      if(!studentData.old_password || !studentData.new_password || !studentData.new_password1 ||
        studentData.old_password === "" || studentData.new_password === "" || studentData.new_password1 === "" ||
        studentData.new_password !== studentData.new_password1){
          setError(t("passwords_dont_match"));
          setLoading(false);
          return;
      }

      if(studentData.new_password === studentData.old_password){
          setError(t("passwords_not_same"));
          setLoading(false);
          return;
      }

      const updateData = {
        old_password: studentData.old_password,
        new_password: studentData.new_password,
        user_email: email,
      };

      let response = await updateStudentPassword(updateData);
      response=handleResponse(response);
      setLoading(false);

      if(response && response.status !== "200"){
        setError(response.message);
      }else{
        setMessage(response.message);
        setStudentData({
          old_password: "",
          new_password: "",
          new_password1: "",
          user_email: "",
        });
      }

    } catch (error) {
      setError(JSON.stringify(error.message));
      setLoading(false);
      console.error("Error updating profile", error);
    }
  };
  

  return (
    <div className="main-wrapper">
      <>
        {/* Header */}
        <StudentHeader activeMenu={"Dashboard"} />
        {/* /Header */}
        {/* Breadcrumb */}
        <div className="breadcrumb-bar breadcrumb-bar-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">{t('settings')}</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/student/student-dashboard">{t('home')}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {t('changePassword')}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="page-content">
          <div className="container">
            <div className="row">
              {/* sidebar */}
              <StudentSidebar />
              {/* /Sidebar */}
              {/* Student Settings */}
              <div className="col-xl-9 col-lg-9 container-with-margin">
                <div className="settings-widget card-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>{t('settings')}</h3>
                      <p>
                        {t('userFullControl')}
                        {t('settings')}
                      </p>
                    </div>
                    <StudentSettingPageHeader />
                    <form onSubmit={handleUpdateProfile}>
                      <div className="checkout-form settings-wrap">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">
                                {t('currentPassword')}
                              </label>
                              <input value={studentData.old_password} type="password" name="old_password" className="form-control" onChange={handleChange}/>
                            </div>
                            <div className="input-block">
                              <label className="form-label">{t('newPassword')}</label>
                              <input value={studentData.new_password} type="password" name="new_password" className="form-control" onChange={handleChange}/>
                            </div>
                            <div className="input-block">
                              <label className="form-label">
                              {t('reTypeNewPassword')}
                              </label>
                              <input value={studentData.new_password1} type="password" name="new_password1" className="form-control" onChange={handleChange}/>
                            </div>
                          </div>
                          {
                            message && (
                              <div className="notification-success mx-3 my-3">
                                {message}
                              </div>
                            )
                          }
                          {
                            error && (
                              <div className="notification-error mx-3 my-3">
                                {error}
                              </div>
                            )
                          }
                          <div className="col-md-12">
                            <button className="btn btn-primary" type="submit">
                              {
                                loading && (
                                  <><i className="fa-solid fa-spinner fa-spin"></i>&nbsp;&nbsp;</>
                                )
                              }
                              {t('resetPassword')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /Student Settings */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>
    </div>
  );
};

export default StudentChangePassword;

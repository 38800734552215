import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    name:localStorage.getItem("Name") || "",// Fetches from localStorage when the store initializes
    email:localStorage.getItem("Email") || "",
    userId:localStorage.getItem("UserId") || "",
    sessionId:localStorage.getItem("SessionId") || "",
    avatar: localStorage.getItem("userAvatar") || "" // Add avatar to state
}

const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setUserName : (state,action) => {
         state.name = action.payload;
         localStorage.setItem('Name', action.payload); // Updates localStorage when the state changes
        },
        setUserEmail : (state,action) => {
         state.email = action.payload;
         localStorage.setItem('Email', action.payload); 
        },
        setUserId : (state,action) => {
         state.userId = action.payload;
         localStorage.setItem('UserId', action.payload); 
        },
        setSessionId : (state,action) => {
         state.sessionId = action.payload;
         localStorage.setItem('SessionId', action.payload); 
        },
        setUserAvatar: (state, action) => {
            state.avatar = action.payload;
            localStorage.setItem('userAvatar', action.payload); 
        }
    },
});
export const {setUserName,setUserEmail,setUserId,setSessionId,setUserAvatar}  = userSlice.actions;
export default userSlice.reducer;
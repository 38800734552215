import React from 'react';
import PropTypes from 'prop-types';
import "./scoreboard.css"
import { useTranslation } from "react-i18next";
const Scoreboard = ({ totalQuestions, correctAnswers, score, status }) => {
    const { t } = useTranslation();
    return (
      <div className="scoreboard">
        <div className="scoreboard-header">{t('scoreboard')}</div>
        <div className="scoreboard-row">
          <div className="scoreboard-label">{t('totalQuestions')}</div>
          <div className="scoreboard-value">{totalQuestions}</div>
        </div>
        <div className="scoreboard-row">
          <div className="scoreboard-label">{t('correctAnswers')}</div>
          <div className="scoreboard-value">{correctAnswers}</div>
        </div>
        <div className="scoreboard-row">
          <div className="scoreboard-label">{t('score')}</div>
          <div className="scoreboard-value">{score}%</div>
        </div>
        <div className="scoreboard-row">
          <div className="scoreboard-label">{t('status')}</div>
          <div className="scoreboard-value" style={{ color: status === 'FAILED' ? 'red' : 'inherit' }}>{status}</div>
        </div>
      </div>
    );
  };
  
Scoreboard.propTypes = {
    totalQuestions: PropTypes.number.isRequired,
    correctAnswers: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  };
export default Scoreboard;

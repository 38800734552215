import React ,{useState} from "react";
import StudentHeader from "../header";
// import StudentSidebar from "../sidebar";
// import Footer from "../../footer";
// import { Link } from "react-router-dom";
import Quiz from './Quiz'; 
import { useLocation } from "react-router-dom";



const StudentQuiz = () => {
  const location = useLocation();
  
  const quizType = location.state?.quizType
  const [currentQuizType] = useState(quizType)

  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"My Quiz Attempts"} />
      {/* Breadcrumb */}
      {/* <div className="breadcrumb-bar breadcrumb-bar-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <h2 className="breadcrumb-title">My Quiz Attempts</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Quiz Attempts
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="page-content">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {/* sidebar */}
            {/* <StudentSidebar /> */}
            {/* /Sidebar */}
            {/* Student Quiz */}
            <Quiz quizType={currentQuizType} /> 
            <div className="col-xl-8 col-lg-10">
            </div>
            {/* /Student Quiz */}
          </div>
    </div>
      </div>

      {/* /Page Content */}
      {/* <Footer /> */}
    </div>
  );
};

export default StudentQuiz;

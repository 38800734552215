import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../imagepath";
import DarkMode from "../../common/darkMode";
// eslint-disable-next-line react/prop-types
import LanguageSwitcher from "../../button/LanguageSwitcher";

const PageHeader = () => {
  // const [mobileSubMenu, setMobileSubMenu] = useState(false);
  // const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  // const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  // const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  // const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  // const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  // const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  // const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  // const [mobileSubMenu5, setMobileSubMenu5] = useState(false);

  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
  };

  // const openMobileSubMenu = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu(!mobileSubMenu);
  // };
  // const openMobileSubMenu2 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu2(!mobileSubMenu2);
  // };
  // const openMobileSubMenu22 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu22(!mobileSubMenu22);
  // };
  // const openMobileSubMenu3 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu3(!mobileSubMenu3);
  // };
  // const openMobileSubMenu32 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu32(!mobileSubMenu32);
  // };
  // const openMobileSubMenu4 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu4(!mobileSubMenu4);
  // };
  // const openMobileSubMenu42 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu42(!mobileSubMenu42);
  // };
  // const openMobileSubMenu43 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu43(!mobileSubMenu43);
  // };
  // const openMobileSubMenu5 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu5(!mobileSubMenu5);
  // };
  const [navbar, setNavbar] = useState(false);

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeHeaderBackground);

  return (
    <>
      <div className="main-wrapper">
        <header className="header header-page">
          <div className="header-fixed">
            <nav
              className={
                navbar
                  ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
                  : "navbar navbar-expand-lg header-nav scroll-sticky"
              }
            >
              <div className="container ">
                <div className="navbar-header">
                  <Link id="mobile_btn" to="#" onClick={openMobileMenu}>
                    <span className="bar-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </Link>
                  <Link to="#" className="navbar-brand logo">
                    <img src={logo} className="img-fluid image-shadow" alt="Logo" />
                  </Link>
                </div>
                <div className="main-menu-wrapper">
                  <div className="menu-header">
                    <Link to="/home" className="menu-logo">
                      <img src={logo} className="img-fluid image-shadow" alt="Logo" />
                    </Link>
                  </div>
                </div>
                <ul className="nav header-navbar-rht">
                  <li className="nav-item only-display-web">
                    <Link className="nav-link header-sign" to="/login">
                      Signin
                    </Link>
                  </li>
                  <li className="nav-item only-display-web">
                    <Link className="nav-link header-login" to="/register">
                      Signup
                    </Link>
                  </li>
                  <LanguageSwitcher navbar={false} isFixed={true} />
                  <DarkMode/>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
};

export default PageHeader;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonial.css';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { davidLangAvatar, jordonKingAvatar } from "../../imagepath";

const Testimonial = () => {
  const {t} = useTranslation()
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, 
    slidesToScroll: 1,
    arrows: true, 
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1, 
          arrows: false, 
        },
      },
    ],
  };
  
  

  return (
<section className="custom-testimonial">
  <div className="custom-testimonial-container">
    <div className="testimonial-header">
      <h2>{t("investorInsights")}</h2>
      <p>{t("studentSupportMessage")}</p>
    </div>
    <div className="custom-testimonial-slider aos" data-aos="fade-up">
      <Slider {...sliderSettings}>
        <TestimonialCard
          avatar={davidLangAvatar}
          name="David Liang"
          text={t("testimonial1")}
        />
        {/* <TestimonialCard
          avatar={sashaEdwardsAvatar}
          name="Sasha Edwards"
          text={t("testimonialNFLPlayer")}
        /> */}
        <TestimonialCard
          avatar={jordonKingAvatar}
          name="Hamza Nadeem"
          text={t("testimonial2")}
        />
      </Slider>
    </div>
  </div>
</section>

  );
};

const TestimonialCard = ({ avatar, name, text }) => {
  return (
    <div className="custom-testimonial-content">
      <img src={avatar} alt="User Avatar" className="avatar-img" />
      <h3 className="user-name">{name}</h3>
      <div className="star-rating">⭐⭐⭐⭐⭐</div>
      <p className="testimonial-text">{text}</p>
    </div>
  );
};


TestimonialCard.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Testimonial;

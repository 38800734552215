import { useTranslation } from 'react-i18next';
import { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { globeIcon} from "../imagepath";
import PropTypes from 'prop-types';

const LanguageSwitcher = ({ navbar,isFixed }) => {
 
  const { i18n } = useTranslation();
  
  const mapUserLanguage = (lang) => {
    return lang === "English" ? "en" : lang === "Spanish" ? "es" : i18n.language;
  };


   const [language, setLanguage] = useState(mapUserLanguage(localStorage.getItem('userLanguage')));

   


   useEffect(() => {
    let retryTimeout;
    
  
    const setInitialLanguage = () => {
      const isLoggedIn = localStorage.getItem('Email'); // Check if the user is logged in
      if (!isLoggedIn) return; // Stop if the user is logged out

      const storedLang = localStorage.getItem('userLanguage');
   
      if (storedLang) {
   
        const mappedLang = mapUserLanguage(storedLang);
        i18n.changeLanguage(mappedLang);
        setLanguage(mappedLang);
      } else {
        retryTimeout = setTimeout(setInitialLanguage, 200);
      }
    };
  
    setInitialLanguage(); 
  
    return () => clearTimeout(retryTimeout);
  }, [i18n]);
  



  const changeLanguage = () => {
    const newLang = language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  };


  return (
    <>
      <li className="nav-item" style={{display: 'block !important'}}>
        <Link
          className="nav-link header-sign"
          onClick={changeLanguage}
          style={{  color: "#fff",background: isFixed
            ? "#0C4D8D" 
            : navbar
            ? "#0C4D8D" 
            : "transparent",}}
        >
          <img
                  src={globeIcon}
                  alt="Sign In Icon"
                  style={{ width: "16px", height: "16px" }}
                />&nbsp;&nbsp;
          {language === 'en' ? 'Español' : 'English'}
        </Link>
      </li>
    </>
  );
};

LanguageSwitcher.propTypes = {
  navbar: PropTypes.bool.isRequired,
  isFixed: PropTypes.bool.isRequired,
};

export default LanguageSwitcher;

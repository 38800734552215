import React from "react";  
import { Link } from "react-router-dom";  
import { useTranslation } from "react-i18next";  
import {office} from "../../imagepath"; 

const PreparingNow = () => {  
  const { t } = useTranslation();  

  return (  
    <div className="preparing-now-card py-5 d-flex align-items-center margin-top-space">  
      <div className="preparing-now-image">  
        <img src={office} alt="Office" className="img-fluid" />  
      </div>  
      <div className="preparing-now-content">  
        <h2>{t("dontLetFutureCatchYou")}</h2>  
        <p>{t("financialFutureInYourHands")}</p>  
        <Link  
          className="btn btn-action-blue my-3 bg-ivot-primary text-white"  
         
          to="/login"  
        >  
          {t("startPreparingNow")}  
        </Link>  
      </div>  
    </div>  
  );  
};  

export default PreparingNow;  

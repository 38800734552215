import React, { useState, useEffect } from 'react';
import { fetchQuestionById, saveQuizResults } from '../../../../api/studentApi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {adjustTimestampFormat} from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import '../../../../assets/css/quiz.css';
import Scoreboard from "./Scoreboard";


const LessonQuiz = ({ quizData,handleLessonCompleted, isLastLesson, onTrainingCompleted,trainingId }) => {
 
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionData, setQuestionData] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState([]);
  const [quizFinished, setQuizFinished] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [sequenceAnswers, setSequenceAnswers] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [quizId,setQuizId] = useState(null);
  const [error, setError] = useState(null); 
  const [quizResult, setQuizResult] = useState({
    status: '',
    correctCount: 0,
    incorrectCount: 0,
    score: 0,
  });

  // const navigate = useNavigate();
  // const navigateToCourse = () => {
  //   onBackToProgramContent()
  //   navigate('/course-details');
  //   }

  useEffect(() => {
    if (quizData && quizData.length > 0 && quizData[0].questions && quizData[0].questions.length > 0) {
      const loadQuestion = async () => {
        const questionId = quizData[0].questions[currentQuestionIndex];
        const question = await fetchQuestionById(questionId);
        setQuestionData(question);
        if (question?.type === 'Chronological Ordering') {
          setSequenceAnswers(question.options); // Initialize the order
        }
        if (!startTime) {
            setStartTime(adjustTimestampFormat(new Date())); // Set start time when the first question loads
        }
        if(!quizId){
          setQuizId(quizData[0].srno)
          }
      };
      loadQuestion();
    }
  }, [quizData, currentQuestionIndex]);

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const onAnswerSelected = (answerIndex) => {
    const maxCount = questionData?.max_count || null;
    const questionType = questionData?.type;

    if (questionType === 'Single Answer') {
      setSelectedAnswerIndex([answerIndex]);
    } else if (questionType === 'Multiple Answers' || questionType === 'Any Answer') {
      if (maxCount && selectedAnswerIndex.length >= maxCount && !selectedAnswerIndex.includes(answerIndex)) {
        return; // Limit reached
      }
      setSelectedAnswerIndex((prev) =>
        prev.includes(answerIndex) ? prev.filter((index) => index !== answerIndex) : [...prev, answerIndex]
      );
    }
  };

  const handleNext = () => {

    if(selectedAnswerIndex.length === 0 && textInput === ""){
      setError(t("errorSelectOption"));
      return;
    }
    setError(null);

    const questionType = questionData?.type;
    let answerToSave = [];
  
    if (questionType === 'Chronological Ordering') {
      // Save chronological ordering as a sequence
      answerToSave = sequenceAnswers.map((option, index) => ({
        option_id: index,
        option_desc: option.option_desc
      }));
    } else {
      // For Single Answer, Multiple Answer, Any Answer, or Freetext
      answerToSave = selectedAnswerIndex.map((index) => {
        if (questionData.options[index].option_type === 'Freetext') {
          // Save the user's text input instead of option_desc for Freetext
          return {
            option_id: index,
            option_desc: textInput // Use the entered textInput value
          };
        } else {
          // For other option types, use the option_desc from the options array
          return {
            option_id: index,
            option_desc: questionData.options[index].option_desc
          };
        }
      });
    }
  
    // Save the answers for the current question
    // Update the existing answer if already saved
  setSelectedAnswers((prev) => {
    const existingAnswerIndex = prev.findIndex(
      (ans) => ans.question_id === questionData.srno
    );
    if (existingAnswerIndex !== -1) {
      const updatedAnswers = [...prev];
      updatedAnswers[existingAnswerIndex] = {
        question_id: questionData.srno,
        answers: answerToSave,
      };
      return updatedAnswers;
    } else {
      return [
        ...prev,
        { question_id: questionData.srno, answers: answerToSave },
      ];
    }
  });
  
    // Reset states for next question
    setTextInput('');
    setSelectedAnswerIndex([]);
    setSequenceAnswers([]);
  
    if (currentQuestionIndex < quizData[0].questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };
  
  

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSaveQuiz = async () => {
    if (selectedAnswerIndex.length === 0 && textInput === "") {
      setError(t("errorSelectOption"));
      return;
    }
    setError(null);
  
    const questionType = questionData?.type;
    let answerToSave = [];
  
    if (questionType === 'Chronological Ordering') {
      // Save chronological ordering as a sequence
      answerToSave = sequenceAnswers.map((option, index) => ({
        option_id: index,
        option_desc: option.option_desc,
      }));
    } else if (questionType === 'Freetext') {
      // Save Freetext input
      answerToSave = [
        {
          option_id: 0, // Assuming single answer entry for Freetext
          option_desc: textInput, // Use the entered textInput value
        },
      ];
    } else {
      // For Single Answer, Multiple Answer, Any Answer
      answerToSave = selectedAnswerIndex.map((index) => ({
        option_id: index,
        option_desc: questionData.options[index].option_desc,
      }));
    }
  
    // Prepare updated selectedAnswers with the current question answer
    const updatedSelectedAnswers = [
      ...selectedAnswers,
      { question_id: questionData.srno, answers: answerToSave },
    ];
  
    setSelectedAnswers(updatedSelectedAnswers); // Update state
  
    // Save the quiz
    const formattedEndTime = adjustTimestampFormat(new Date());
    const quizCompletionData = {
      student_email: localStorage.getItem('Email'),
      quiz_id: quizId,
      start_time: startTime,
      end_time: formattedEndTime,
      results: updatedSelectedAnswers,
      training_id: trainingId,
    };
  
    setQuizFinished(true);
    try {
      const response = await saveQuizResults(quizCompletionData);
      if (response.success) {
        const { status, correct_count, incorrect_count, score } = response.data;
  
        setQuizResult({
          status,
          correctCount: correct_count,
          incorrectCount: incorrect_count,
          score,
        });
  
        // await handleLessonCompleted();
  
        if (isLastLesson) {
          onTrainingCompleted();
        }
        setError(null);
      } else {
        console.error('Failed to save quiz results:', response.message);
        setError('Failed to save quiz results:', response.message);
      }
    } catch (error) {
      console.error('Error saving quiz results:', error);
      setError('Error saving quiz results:', error);
    }
  };
  
  
  
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const reorderedOptions = Array.from(sequenceAnswers);
    const [removed] = reorderedOptions.splice(source.index, 1);
    reorderedOptions.splice(destination.index, 0, removed);

    setSequenceAnswers(reorderedOptions);
  };

  if (!quizData || !quizData[0].questions || quizData[0].questions.length === 0) {
    return <p>No questions available for this quiz.</p>;
  }

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);
  
  
  return (
    <div className="quiz-container">
      {!quizFinished && questionData ? (
        <div>
          <div className="quiz-header no-padding-left">
            <div className="quiz-info">
              <div>
                <span className="active-question-no">{addLeadingZero(currentQuestionIndex + 1)}</span>
                <span className="total-question">/{addLeadingZero(quizData[0].questions.length)}</span>
              </div>
            </div>
            {/* Render context if present */}
            {questionData.context &&  <h2 dangerouslySetInnerHTML={{ __html: questionData.context }}></h2>}
            <h2 dangerouslySetInnerHTML={{ __html: questionData.question }}></h2>
          </div>

          {questionData.type === 'Chronological Ordering' ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sequence">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {sequenceAnswers.map((option, index) => (
                      <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="drag-item"
                          >
                            {option.option_desc}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <ul>
              {questionData.options?.map((option, index) => (
                <li
                  key={index}
                  onClick={() => onAnswerSelected(index)}
                  className={`${selectedAnswerIndex.includes(index) ? 'selected-answer' : ''}`}
                >
                  {option.option_type === 'Normal' && option.option_desc}
                  {option.option_type === 'Image' && <img src={option.option_image_url} alt="Option" className="img-fluid" />}
                  {option.option_type === 'Freetext' && (
                    <input
                      type="text"
                      className="form-control"
                      value={textInput}
                      placeholder={option.option_desc}
                      onChange={(e) => handleTextInputChange(e, index)}
                    />
                  )}
                </li>
              ))}
            </ul>
          )}
          {error && (
              <div className="my-2 px-2" style={{borderLeft: '3px solid red'}}>
                <label style={{color: 'red'}}>{t('errorOccurred')}</label>
                <pre>{JSON.stringify(error, null, 2)}</pre>
              </div>
            )}
          <div className="flex-between">
            <button onClick={handlePrevious} disabled={currentQuestionIndex === 0} className="btn btn-secondary px-4">
            {t('previous')}
            </button>

            <button
              onClick={currentQuestionIndex === quizData[0].questions.length - 1 ? handleSaveQuiz : handleNext}
              className="btn btn-primary px-4"
            >
              {currentQuestionIndex === quizData[0].questions.length - 1 ? t('finish') : t('next')}
            </button>
          </div>
        </div>
      ) : quizFinished &&  quizData && quizData?.length > 0 ? (
        <>
          {quizData[0].quiz_type === 'Opinion Quiz' ? (
            <div className="text-center" style={{ marginTop: '20px' }}>
              <p>{t('thanksForSubmittingQuiz')}</p>
              <Link
                className="button-link"
                onClick={handleLessonCompleted}
              >
                {t('goBackToLessons')}
              </Link>
            </div>
          ) : (
            <div className="text-center" style={{ marginTop: '20px' }}>
           <p>{t('thanksForSubmittingQuiz')}</p>
               <Scoreboard 
                  totalQuestions={quizResult.correctCount + quizResult.incorrectCount}
                  correctAnswers={quizResult.correctCount} 
                  score={quizResult.score} 
                  status={quizResult.status} 
                />
              
              <Link
                className="button-link"
                onClick={handleLessonCompleted}
              >
                {t('goBackToLessons')}
              </Link>
            </div>
          )}
        </>
      ) : (
        <div className="text-center" style={{marginTop: '100px'}}>
          <p>{t('loadingQuestion')}...</p>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">{t('loading')}...</span>
          </div>
        </div>
      )}
    </div>
  );
};

LessonQuiz.propTypes = {
  quizData: PropTypes.arrayOf(
    PropTypes.shape({
      questions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
      srno: PropTypes.number.isRequired,
      quiz_type: PropTypes.string,
    })
  ).isRequired,
  handleLessonCompleted: PropTypes.func.isRequired, 
  isLastLesson: PropTypes.bool.isRequired, 
  onTrainingCompleted: PropTypes.func.isRequired,
  trainingId: PropTypes.number.isRequired
};


export default LessonQuiz;





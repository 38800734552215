import React, { useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { calculateToolkit6 } from "../../../../../api/programApi";
import {formatNumberWithCommasAndMillions} from "../../../../../utils/helper";
import PropTypes from 'prop-types';
import './tool.css';

const Toolkit6 = ({ data }) => {
  
    const [calculatedData, setCalculatedData] = useState({});

  useEffect(() => {
    const fetchInitialData = async () => {
      if (data) {
        const initialCalculatedData = await calculateToolkit6();
        setCalculatedData(initialCalculatedData);
      }
    };
    fetchInitialData();
  }, [data]);



  if (!data) {
    return <div>Loading...</div>;
  }


  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    return field ? field : { field_value: '', info: '' };
  };

  const renderFieldWithInfo = (fieldName) => {
    const field = getField(fieldName);

    return (
      <div className="tool-label">
        
        {field.info && (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={`tooltip-${fieldName}`}>
                {field.info}
              </Tooltip>
            }
          >
            <i className="fas fa-info-circle me-2" style={{ cursor: 'pointer' }}></i>
          </OverlayTrigger>
        )}
        {field.field_value}
      </div>
    );
  };

  return (
    <div className="share-calculator"> 
      <h3 className="background-blue font-white tool-header"><i className="fas fa-calculator me-2"></i>{getField("lesson_header").field_value}</h3>

      <div className="tool-row">
        <div className="tool-column tool-label">{getField("company").field_value}</div>
        <div className="tool-column tool-label">{getField("symbol").field_value}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{getField("company_name").field_value}</div>
        <div className="tool-column">{getField("symbol_name").field_value}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("shares_outstanding_one").field_value}</div>
        <div className="tool-column tool-label">{getField("market_price").field_value}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{formatNumberWithCommasAndMillions(Number(calculatedData?.shares_outstanding_one_value))}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(calculatedData?.market_price_value)}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("quarterly_income_statement")}</div>
        <div className="tool-column tool-label">{getField("baseline").field_value}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("sales")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(calculatedData?.sales_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("cogs")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(calculatedData?.cogs_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("gross_profit")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(calculatedData?.gross_profit_value)}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("sales_market")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(calculatedData?.sales_market_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("general")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(calculatedData?.general_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("operating")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(calculatedData?.operating_value))}</div>
      </div>

      <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("tax")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(calculatedData?.tax_value))}</div>
      </div>

        <div className="tool-row">
        <div className="tool-column">{renderFieldWithInfo("depre")}</div>
        <div className="tool-column">${formatNumberWithCommasAndMillions(Number(calculatedData?.depre_value))}</div>
        </div>  


      <div className="tool-row">
        <div className="tool-column tool-label">{renderFieldWithInfo("ocf")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(calculatedData?.ocf_value))}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{renderFieldWithInfo("annual_ocf")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(calculatedData?.annual_ocf_value))}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{renderFieldWithInfo("baseline_value")}</div>
        <div className="tool-column ">${formatNumberWithCommasAndMillions(Number(calculatedData?.baseline_v_value))}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("shares_outstanding").field_value}</div>
        <div className="tool-column ">{formatNumberWithCommasAndMillions(Number(calculatedData?.shares_outstanding_one_value))}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{renderFieldWithInfo("baseline_value_per_share")}</div>
        <div className="tool-column ">$51</div>
      </div>
    </div>
  );
};

Toolkit6.propTypes = {
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string.isRequired,
          field_value: PropTypes.string.isRequired,
          info: PropTypes.string, // Optional info field
        })
      ).isRequired,
    }).isRequired,
  };
  

export default Toolkit6;

import React,{useState,
  useEffect
} from "react";
import { Link } from "react-router-dom";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import { User16 } from "../../imagepath";
import { Oval } from 'react-loader-spinner';
import StudentSettingPageHeader from "./settingPageHeader";
import {getStudentDetails,updateStudentDetails} from "../../../api/studentApi"
import { useDispatch } from "react-redux";
import { setUserAvatar } from "../../common/redux/userSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const StudentSetting = () => {
  const dispatch = useDispatch();
  const avatar = useSelector((state) => state.userSlice.avatar) || localStorage.getItem("userAvatar");
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();
  const [studentData, setStudentData] = useState({
    first_name: "",
    last_name: "",
    contact_no: "",
    language: "",
    email: "",
  });

  const fetchStudentDetails = async () => {
    const storedStudentDetails = localStorage.getItem("studentDetails");
    const email = localStorage.getItem("Email");

    if (storedStudentDetails) {
     
      const parsedDetails = JSON.parse(storedStudentDetails);
      setStudentData({
        first_name: parsedDetails.first_name,
        last_name: parsedDetails.last_name,
        contact_no: parsedDetails.contact_no,
        language: parsedDetails.language,
        email: parsedDetails.email,
      });
    } else if (email) {
      
      const response = await getStudentDetails(email);
      if (response?.data) {
        const data = response.data.data[0];
       
        localStorage.setItem("studentDetails", JSON.stringify(data));
      
        setStudentData({
          first_name: data.first_name,
          last_name: data.last_name,
          contact_no: data.contact_no,
          language: data.language,
          email: data.email,
        });
      }
    }
  };

  useEffect(() => {
    fetchStudentDetails();
  }, []);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentData({ ...studentData, [name]: value });
  };

   // Handle update profile
   const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const updateData = {
        first_name: studentData.first_name,
        last_name: studentData.last_name,
        contact_no: studentData.contact_no,
        language: studentData.language,
        email: studentData.email, // email remains unchanged
      };

      await updateStudentDetails(updateData);
      alert("Profile updated successfully!");

      localStorage.removeItem("studentDetails");
      fetchStudentDetails();
    } catch (error) {
      console.error("Error updating profile", error);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setUploading(true); 
      reader.onloadend = async () => {
        const base64Image = reader.result;
        const fileName = file.name;
  
        if (studentData.email) {
          const updateData = {
            email: studentData.email,
            avatar: {
              files: [base64Image],
              fileNames: [fileName],
            },
            status: "Active"
          };
  
          try {
            await updateStudentDetails(updateData);
            const response = await getStudentDetails(studentData.email);
            const updatedAvatar = response?.data?.data[0]?.avatar[0];
            dispatch(setUserAvatar(updatedAvatar)); 
           
          } catch (error) {
            console.error("Error updating avatar:", error);
          } finally {
            setUploading(false); 
          }
        }
      };
      reader.readAsDataURL(file); 
    }
  };
  

  return (
    <div className="main-wrapper">
      <>
        {/* Header */}
        <StudentHeader activeMenu={"Settings"} />
        {/* /Header */}
        {/* Breadcrumb */}
        {/* <div className="breadcrumb-bar breadcrumb-bar-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">{t('settings')}</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/student/student-dashboard">{t('home')}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {t('editProfile')}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="page-content bg-light-grayish-blue">
          <div className="container">
            <div className="row">
              {/* sidebar */}
              <StudentSidebar />
              {/* /Sidebar */}
              {/* Student Settings */}
              <div className="col-xl-9 col-lg-9 container-with-margin" style={{marginTop:"60px"}}>
                <div className="settings-widget card-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>{t('settings')}</h3>
                      <p>
                        {t('userFullControl')}
                      </p>
                    </div>
                    <StudentSettingPageHeader />
                    <form onSubmit={handleUpdateProfile}>
                      <div className="course-group profile-upload-group mb-0 d-flex">
                        <div className="course-group-img profile-edit-field d-flex align-items-center">
                          <Link
                            to="/student/student-profile"
                            className="profile-pic"
                          >
                            <img src={avatar || User16} alt="Img" className="img-fluid" />
                          </Link>
                          <div className="profile-upload-head">
                            <h4>
                              <Link to="/student/student-profile">{t('yourAvatar')}</Link>
                            </h4>
                            <p>
                              {t('avatarInstructions')}
                            </p>
                            <div className="new-employee-field">
                              <div className="d-flex align-items-center mt-2">
                              <div className="image-upload mb-0">
                                <input type="file" onChange={handleFileChange} disabled={uploading} />
                                <div className="image-uploads">
                                  {uploading ? (
                                    <Oval color="#00BFFF" height={50} width={50} />
                                  ) : (
                                    <i className="bx bx-cloud-upload" />
                                  )}
                                </div>
                            </div>
                                {/* <div className="img-delete">
                                  <Link
                                    to="#"
                                    className="delete-icon"
                                  >
                                    <i className="bx bx-trash" />
                                  </Link>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="checkout-form settings-wrap">
                        <div className="edit-profile-info">
                          <h5>{t('personalDetails')}</h5>
                          <p>{t('editPersonalInformation')}</p>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">{t('firstName')}</label>
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                value={studentData.first_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">{t('lastName')}</label>
                              <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                value={studentData.last_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">User Name</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="studentdemo"
                              />
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">{t('contactNumber')}</label>
                              <input
                                type="text"
                                className="form-control"
                                name="contact_no"
                                value={studentData.contact_no}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="input-block">
                            <label className="form-label">{t('language')}</label>
                              <select
                                name="language"
                                className="form-control"
                                value={studentData.language}
                                onChange={handleChange}
                              >
                                <option value="English">{t('english')}</option>
                                <option value="Spanish">{t('spanish')}</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="input-block">
                            <label className="form-label">{t('email')}</label>
                              <input
                                type="email"
                                className="form-control"
                                value={studentData.email}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button className="btn btn-primary" type="submit">
                              {t('updateProfile')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /Student Settings */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>
    </div>
  );
};

export default StudentSetting;

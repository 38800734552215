import React,{useState,
    useEffect
} from "react";
import "./tool.css";
import { ToolkitImageLogo } from "../../../../imagepath";
import PropTypes from "prop-types";
import {formatNumberWithCommasAndMillions, handlePercentageChange,stripPercentage } from "../../../../../utils/helper";

import {CalculateProgram3Toolkit5} from "../../../../../api/programApi";

import { useSelector } from "react-redux";


const Program3Toolkit5 = ({data}) => {

   

    const [growthRateQuarter, setGrowthRateQuarter] = useState("0");
    const [percentSalesQuarter, setPercentSalesQuarter] = useState("0");
    const [calculatedValue, setCalculatedValue] = useState(null);
    const [logoLoading, setLogoLoading] = useState(true);
    const [logoUrl, setLogoUrl] = useState("");
    
    const userName = useSelector((state) => state.userSlice.name);

    // Fetch data on page load
    useEffect(() => {
        setLogoLoading(true);
        const fetchInitialData = async () => {
        try {
            const initialResponse = await CalculateProgram3Toolkit5(
            stripPercentage(growthRateQuarter),
            stripPercentage(percentSalesQuarter)
            );
            setCalculatedValue(initialResponse);
            setLogoUrl(initialResponse?.company_logo); // Set dynamic logo URL from API
        } catch (error) {
            console.error("Error fetching initial data", error);
        } finally {
            setLogoLoading(false);
          }
        };
        fetchInitialData();
    }, []);

    const handleBlur = async () => {
        try {
          const growthRateQuarterValue = stripPercentage(growthRateQuarter);
          const percentSalesQuarterValue = stripPercentage(percentSalesQuarter);
          
          const response = await CalculateProgram3Toolkit5(growthRateQuarterValue,percentSalesQuarterValue);
    
          setCalculatedValue(response);
            
        } catch (error) {
          console.error("Error calculating PMOSToolkit1 value", error);
        }
      };

    const getField = (fieldName) => {
        const field = data?.data?.find((item) => item.field_name === fieldName);
        return field ? field.field_value : "";
      };

     
  return (
    <div className="share-calculator toolkit4-container">
        <div  className="toolkit4-content">
        <table className="tool-table">
            <thead>
            <tr className="background-color-white">
              <th colSpan="2" >
                <img 
                src={ToolkitImageLogo}
                className="img-fluid"
                alt="Logo" 
                />
              </th>
                <th colSpan="2">
                {logoLoading ? (
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        <img src={logoUrl} alt="Logo" className="img-fluid" />
                    )}
                </th>
              
            </tr>
            <tr >
            <th className="background-color-white text-center"  colSpan="4">
                    <h4>{getField("tool_header")}</h4>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr className="background-blue font-white">    
                <td>{getField("company")}</td>
                <td>{getField("symbol")}</td>
                <td>{getField("latest")}</td>
                <td>{getField("next_report")}</td>
            </tr>
            <tr>
                <td className="td-tool-value">{calculatedValue?.company_value}</td>
                <td className="td-tool-value">{calculatedValue?.symbol_value}</td>
                
                <th>{getField("latest_value")}</th>
                <th>{getField("next_report_value")}</th>
            </tr>
            <tr>
                <th>{getField("analyst")}</th>
                <th>{getField("shares")}</th>
                <th>{getField("price")}</th>
                <th>{getField("mkt_cap")}</th>
            </tr>
            <tr>
                <td className="exchange-rate-result">{userName}</td>
                <td className="td-tool-value">{formatNumberWithCommasAndMillions(calculatedValue?.shares_value)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.price_value)}</td>
                <td className="exchange-rate-result">	{calculatedValue?.mkt_cap_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.mkt_cap_value)}` : ""}
                </td>
            </tr>
            <tr className="background-blue font-white" >
                <td>{getField("valuation_summary")}</td>
                <td>{getField("year_ago_label")}</td>
                <td>{getField("baseline_label")}</td>
                <td>{getField("forecast_label")}</td>
            </tr>
            <tr>
                <td></td>
                <th>{getField("year_ago")}</th>
                <th>{getField("baseline")}</th>
                <th>{getField("forecast")}</th>
            </tr>
            <tr>
                <th>{getField("quarterly_operating")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.quarterly_operating_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.quarterly_operating_quarter_24)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.quarterly_operating_quarter_27)}</td>

            </tr>
            <tr>
                <th>{getField("operating_cash")}</th>
                <td className="td-tool-value">{calculatedValue?.operating_cash_quarter_23}%</td>
                <td className="td-tool-value">{calculatedValue?.operating_cash_quarter_24}%</td>
                <td className="exchange-rate-result">	{calculatedValue?.operating_cash_quarter_27 !== undefined ? `${calculatedValue?.operating_cash_quarter_27}%` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("enterprise")}</th>
                <td className="td-tool-value">${calculatedValue?.enterprise_quarter_23}</td>
                <td className="td-tool-value">${calculatedValue?.enterprise_quarter_24}</td>
                <td className="exchange-rate-result">	{calculatedValue?.enterprise_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.enterprise_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("implied")}</th>
                <td className="td-tool-value">{calculatedValue?.implied_quarter_23}%</td>
                <td className="td-tool-value">{calculatedValue?.implied_quarter_24}%</td>
                <td className="exchange-rate-result">	{calculatedValue?.implied_quarter_27 !== undefined ? `${calculatedValue?.implied_quarter_27}%` : ""}
                </td>
            </tr>
            <tr className="background-blue font-white">
                <td>{getField("quarterly_income_statement")}</td>
                <td>{getField("year_ago_label")}</td>
                <td>{getField("baseline_label")}</td>
                <td>{getField("forecast_label")}</td>
            </tr>
            <tr>
                <th>{getField("total_sales")}</th>
                <th>{getField("year_ago")}</th>
                <th>{getField("baseline")}</th>
                <th>{getField("forecast")}</th>
            </tr>
            <tr>
                <th>{getField("sales")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.sales_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.sales_quarter_24)}</td>
                
                <td className="exchange-rate-result">	{calculatedValue?.sales_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.sales_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("growth_rate")}</th>
                <td></td>
                <td className="td-tool-value">{calculatedValue?.growth_rate_quarter_24}%</td>
                <td><input
                className="form-input"
                type="text"
                value={growthRateQuarter}
                onChange={handlePercentageChange(setGrowthRateQuarter)}
                onBlur={handleBlur}
                /></td>
            </tr>
            <tr>
                <th>{getField("cogs")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.cogs_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.cogs_quarter_24)}</td>
               
                <td className="exchange-rate-result">	{calculatedValue?.cogs_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.cogs_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("percent_sales")}</th>
                <td className="td-tool-value">{calculatedValue?.percent_sales_quarter_23}%</td>
                <td className="td-tool-value">{calculatedValue?.percent_sales_quarter_24}%</td>
                <td><input
                className="form-input"
                type="text"
                value={percentSalesQuarter}
                onChange={handlePercentageChange(setPercentSalesQuarter)}
                onBlur={handleBlur}
                /></td>
            </tr>
            <tr>
                <th>{getField("gross_profit")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.gross_profit_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.gross_profit_quarter_24)}</td>
                <td className="exchange-rate-result">	{calculatedValue?.gross_profit_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.gross_profit_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("gross_margin")}</th>
                <td className="td-tool-value">{calculatedValue?.gross_margin_quarter_23}%</td>
                <td className="td-tool-value">{calculatedValue?.gross_margin_quarter_24}%</td>
                <td className="exchange-rate-result">	{calculatedValue?.gross_margin_quarter_27 !== undefined ? `${calculatedValue?.gross_margin_quarter_27}%` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("operating_profit")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.operating_profit_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.operating_profit_quarter_24)}</td>
                <td className="exchange-rate-result">	{calculatedValue?.operating_profit_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.operating_profit_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("operating_margin")}</th>
                <td className="td-tool-value">{calculatedValue?.operating_margin_quarter_23}%</td>
                <td className="td-tool-value">{calculatedValue?.operating_margin_quarter_24}%</td>
                <td className="exchange-rate-result">	{calculatedValue?.operating_margin_quarter_27 !== undefined ? `${calculatedValue?.operating_margin_quarter_27}%` : ""}
                </td>
            </tr>
            <tr className="background-blue font-white">
                <td>{getField("valuation_details")}</td>
                <td>{getField("year_ago_label")}</td>
                <td>{getField("baseline_label")}</td>
                <td>{getField("forecast_label")}</td>
            </tr>
            <tr>
                <th>{getField("cash_flow_value")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.cash_flow_value_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.cash_flow_value_quarter_24)}</td>
                <td className="exchange-rate-result">	{calculatedValue?.cash_flow_value_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.cash_flow_value_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("net_cash")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.net_cash_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.net_cash_quarter_24)}</td>
                <td className="exchange-rate-result">	{calculatedValue?.net_cash_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.net_cash_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("enterprise_value")}</th>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.enterprise_value_quarter_23)}</td>
                <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.enterprise_value_quarter_24)}</td>
                <td className="exchange-rate-result">	{calculatedValue?.enterprise_value_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.enterprise_value_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("fully_diluted")}</th>
                <td className="td-tool-value">{formatNumberWithCommasAndMillions(calculatedValue?.fully_diluted_quarter_23)}</td>
                <td className="td-tool-value">{formatNumberWithCommasAndMillions(calculatedValue?.fully_diluted_quarter_24)}</td>
                <td className="exchange-rate-result">	{calculatedValue?.fully_diluted_quarter_27 !== undefined ? `${formatNumberWithCommasAndMillions(calculatedValue?.fully_diluted_quarter_27)}` : ""}
                </td>
            </tr>
            <tr>
                <th>{getField("implied_value")}</th>
                <td className="td-tool-value">${calculatedValue?.implied_value_per_quarter_23}</td>
                <td className="td-tool-value">${calculatedValue?.implied_value_per_quarter_24}</td>
                <td className="exchange-rate-result">	{calculatedValue?.implied_value_per_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.implied_value_per_quarter_27)}` : ""}
                </td>
            </tr>
            </tbody>
        </table>
      </div>
    </div>
  );
};

Program3Toolkit5.propTypes = {
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string.isRequired,
          field_value: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

export default Program3Toolkit5;

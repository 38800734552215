
import React, { useState, useEffect } from "react";
import { Play } from "../../../imagepath";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getCourses } from "../../../../api/programApi";
import { useTranslation } from "react-i18next";

const CourseContent = ({ onStartLesson, trainingProgram,fetchLessonStatuses }) => {
  const { t } = useTranslation();
  const [openCourse, setOpenCourse] = useState(null); // Track which course is open
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [lessonStatus, setLessonStatus] = useState([]); // Track user lesson status
 
  const fetchCourses = async () => {
    const coursesData = await getCourses(trainingProgram.srno);
    if (coursesData?.data?.courses) {
      //const filteredCourse = coursesData?.data?.filter(currentCourse => currentCourse.program_id[0] === trainingProgram.srno);
      setCourses(coursesData.data.courses);
    }
    if (coursesData?.data?.lessons) {
      //const filteredCourse = coursesData?.data?.filter(currentCourse => currentCourse.program_id[0] === trainingProgram.srno);
      setLessons(coursesData.data.lessons);
    }
  };

  // Fetch Lessons
  useEffect(() => {
    /*
    const fetchLessons = async () => {
      const lessonData = await getLessons(trainingProgram.srno);
      if (lessonData) {
        setLessons(lessonData?.data); // Store all lessons and filter in the return
        fetchCourses();
      }
    };
    fetchLessons();
    */
    fetchCourses();
  }, [trainingProgram]);


  useEffect(() => {
    const updateLessonStatus = async () => {
      if (openCourse) {
        try {
          const statusData = await fetchLessonStatuses(openCourse); // Use the prop function
         
          if (statusData) {
            setLessonStatus(statusData); // Update the lesson status with the response
          }
        } catch (error) {
          console.error("Error updating lesson status:", error);
        }
      }
    };
  
    updateLessonStatus(); // Call the function to update lesson status
  }, [openCourse, fetchLessonStatuses]);
  

 
  
  return (
    <div className="card content-sec">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-6">
            <h5 className="subs-title">{t('programContent')}</h5>
          </div>
          <div className="col-sm-6 text-sm-end">
            <h6>{courses.length} {t('courses')}</h6>
          </div>
        </div>
        <div className="course-card">
          {courses.map((course) => {
            // Filter lessons for the current course
            const courseLessons = lessons.filter(lesson => lesson.course_id[0] === course.srno);
           
            return (
              <div key={course.srno}>
                <h6 className="cou-title">
                  <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    to={`#collapseCourse${course.srno}`}
                    aria-expanded={openCourse === course.srno}
                    onClick={() => setOpenCourse(openCourse === course.srno ? null : course.srno)}
                    aria-controls={`collapseCourse${course.srno}`}
                  >
                    {course.course_header}
                  </Link>
                </h6>

                <div id={`collapseCourse${course.srno}`} className={`card-collapse collapse ${openCourse === course.srno ? 'show' : ''}`}>
                  <ul>
                    {courseLessons.length > 0 ? (
                      courseLessons.map((lesson, index) => {
                        const isLastLesson = index === courseLessons.length - 1;
                    
                        // Find the status of the current lesson
                        const lessonStatusObj = lessonStatus.find(status => status.lesson_id === lesson.srno);
                        const lessonStatusText = lessonStatusObj ? lessonStatusObj.status : null;
                    
                        // Find the status of the previous lesson
                        const prevLessonStatusObj = index > 0 ? lessonStatus.find(status => status.lesson_id === courseLessons[index - 1].srno) : null;
                        const prevLessonStatusText = prevLessonStatusObj ? prevLessonStatusObj.status : null;
                    
                        return (
                          <li className="lesson-list-item" key={lesson._id.$oid}>
                              <p>
                                  <img src={Play} alt="" className="me-2" />
                                  <span 
                                  // style={{ textDecoration: lesson.status === "Inactive" ? "line-through" : "none" }}
                                  >
                                    {t("lesson")} {lesson.lesson_no.toString()}. {lesson.lesson_header}
                                  </span>
                              </p>

                              {lesson.status === "Active" && (
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                      {/* Completed Status */}
                                      {lessonStatusText === "Completed" && (
                                          <span className="web-badge me-2" style={{ background: "#9f9a93" }}>{t("completed")}</span>
                                      )}

                                      {/* First Lesson or First Available Lesson */}
                                      {lessonStatusText === null && index === 0 && (
                                          <Link
                                              to="#"
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  onStartLesson(lesson, isLastLesson, openCourse);
                                              }}
                                          >
                                              {t('start')}
                                          </Link>
                                      )}

                                      {/* Next Lesson after Completion of Previous */}
                                      {lessonStatusText === null && prevLessonStatusText === "Completed" && (
                                          <Link
                                              to="#"
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  onStartLesson(lesson, isLastLesson, openCourse);
                                              }}
                                          >
                                              {t('start')}
                                          </Link>
                                      )}

                                      {/* For other lessons show "Upcoming..." if status is null and previous is not completed */}
                                      {lessonStatusText === null && prevLessonStatusText !== "Completed" && index !== 0 && (
                                          <span>{t('nextLevel')}...</span>
                                      )}

                                      {/* If lesson has a status other than null (but not completed) */}
                                      {lessonStatusText && lessonStatusText !== "Completed" && (
                                          <Link
                                            className="btn btn-outline-primary btn-sm"
                                            style={{     borderRadius: "50px", padding: "3px 25px 3px 25px", textDecoration: "none" }}
                                            to="#"
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  onStartLesson(lesson, isLastLesson);
                                              }}
                                          >
                                              {t('start')}
                                          </Link>
                                      )}
                                      {lessonStatusText && lessonStatusText === "Completed" && lesson.allow_revisit === "Yes" && (
                                          <Link
                                            className="btn btn-outline-primary btn-sm"
                                            style={{     borderRadius: "50px", padding: "3px 25px 3px 25px", textDecoration: "none" }}
                                              to="#"
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  onStartLesson(lesson, isLastLesson);
                                              }}
                                          >
                                              {t('revisit')}
                                          </Link>
                                      )}
                                  </div>
                              )}
                          </li>
                        );
                      })
                    ) : (
                      <li>{t('noLessonsAvailableForThisCourse')}</li>
                    )}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CourseContent.propTypes = {
  trainingProgram: PropTypes.shape({
    srno: PropTypes.number.isRequired,
    allow_revisit: PropTypes.string.isRequired,
  }).isRequired,
  onStartLesson: PropTypes.func.isRequired,
  fetchLessonStatuses: PropTypes.func.isRequired,
};

export default CourseContent;


import React from "react";
import { useTranslation } from "react-i18next";
import { barGraph,billGroup,dollarGroup,deductionGroup} from "../../imagepath"; // Update the correct image path

const FinancialFutureCategory = () => {
  const { t } = useTranslation();

  return (
    <section className="section financial-future-category d-flex align-items-center margin-top-space ">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Side: Text Content */}
          <div className="col-md-6 text-left">
            <h2 style={{ fontFamily: "Helvetica", fontWeight: 500 }}>
              {t("yourFinancialFuture")}
            </h2>
            <p className="description">
              {t("traditionalMethodsDescription")}
            </p>
            <ul className="points-list">
              <li>
                <div className="check-icon-circle">
                  <i className="fa fa-check"></i>
                </div>
                <span>{t("stayOnTop")}</span>
              </li>
              <li>
                <div className="check-icon-circle">
                  <i className="fa fa-check"></i>
                </div>
                <span>{t("makeSmartDecisions")}</span>
              </li>
              <li>
                <div className="check-icon-circle">
                  <i className="fa fa-check"></i>
                </div>
                <span>{t("personalizedStrategies")}</span>
              </li>
            </ul>
          </div>

          {/* Right Side: Image */}
          <div className="col-md-6 text-center">
          <div className="barGraph-container position-relative">
            <img src={billGroup} alt="" className="background-small-image billGroup" />
            <img src={dollarGroup} alt="" className="background-small-image dollarGroup" />
            <img src={deductionGroup} alt="" className="background-small-image deductionGroup" />

            <img src={barGraph} alt="" className="img-fluid barGraph-image" />
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinancialFutureCategory;

import React,{useState} from "react";
import { LoginImg, logo } from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {forgotPasswordAPI,handleResponse} from "../../../api/programApi";

const ForgotPassword = () => {
  const { t } = useTranslation();
 
 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState(""); // Track email input
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

    var settings = {
        //autoWidth: true,
        items: 2,
        margin: 25,
        dots: true,
        nav: true,
        navText: [
          '<i className="fas fa-arrow-left"></i>',
          '<i className="fas fa-arrow-right"></i>',
        ],
    
        loop: true,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 1,
          },
          1170: {
            items: 1,
          },
        },
      };
    
      const handleSubmit = async (e) => {
       e.preventDefault();
       if (!email) {
        setErrorMessage("Email cannot be empty."); 
        return; 
      }
    
        try {
          const response = await forgotPasswordAPI(email);
          const data = handleResponse(response); 

          if (data.status === "200") {
            setSuccessMessage(data.message);
            setIsSubmitted(true); // Show success message
          } else {
            setErrorMessage("Failed to reset password. Please try again.");
          }
        } catch (error) {
          setErrorMessage("Error occurred while resetting password.");
        }
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
          <OwlCarousel 
            {...settings}
            className="owl-carousel login-slide owl-theme">
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={LoginImg}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                  {t('welcomeMessage')}
                  </h2>
                  <p>
                  {t('studentGoal')}
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={LoginImg}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                  {t('welcomeMessage')}
                  </h2>
                  <p>
                  {t('studentGoal')}
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img
                    src={LoginImg}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                  {t('welcomeMessage')}
                  </h2>
                  <p>
                  {t('studentGoal')}
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Logo"
                  />
                  <div className="back-home">
                    <Link to="/home">{t('backToHomeAgain')}</Link>
                  </div>
                </div>


                {isSubmitted ? (
                  <div className="reset-success-message">
                    <h1>{t('forgot_password')}</h1>
                    <p>{successMessage}</p>
                  </div>
                ) : (
                  <div>
                    <h1>{t('forgot_password')}</h1>
                    <div className="reset-password">
                      <p>{t('reset_password_instruction')}</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="input-block">
                        <label className="form-control-label">{t('email')}</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder={t('enterEmailAddress')}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)} // Handle input change
                        />
                      </div>
                      <div className="d-grid">
                        <button className="btn btn-start" type="submit">
                          {t('submit')}
                        </button>
                      </div>
                    </form>
                    {errorMessage && <p className="error">{errorMessage}</p>}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default ForgotPassword;

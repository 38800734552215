import React,{useEffect, useState} from "react";
import PropTypes from "prop-types";
import { StarBucksLogo } from "../../../../imagepath";
import { useSelector } from "react-redux";
import {CalculateProgram3Toolkit3} from "../../../../../api/programApi";
import {formatNumberWithCommasAndMillions, handlePercentageChange,stripPercentage } from "../../../../../utils/helper";


import "./tool.css";

const Program3Toolkit3 = ({data }) => {

    const [growthRateQuarter, setGrowthRateQuarter] = useState("0");
    const [percentSalesQuarter, setPercentSalesQuarter] = useState("0");
    const [calculatedValue, setCalculatedValue] = useState(null);
    
    const userName = useSelector((state) => state.userSlice.name);
    
    const getField = (fieldName) => {
        const field = data?.data?.find((item) => item.field_name === fieldName);
        return field ? field.field_value : "";
      };

      useEffect(() => {
        
        const fetchInitialData = async () => {
        try {
            const initialResponse = await CalculateProgram3Toolkit3(
            stripPercentage(growthRateQuarter),
            stripPercentage(percentSalesQuarter)
            );
            setCalculatedValue(initialResponse);
            
        } catch (error) {
            console.error("Error fetching initial data", error);
        } 
        };
        fetchInitialData();
    }, []);

      const handleBlur = async () => {
        try {
          const growthRateQuarterValue = stripPercentage(growthRateQuarter);
          const percentSalesQuarterValue = stripPercentage(percentSalesQuarter);
          
          const response = await CalculateProgram3Toolkit3(growthRateQuarterValue,percentSalesQuarterValue);
    
          setCalculatedValue(response);
            
        } catch (error) {
          console.error("Error calculating PMOSToolkit1 value", error);
        }
      };

  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">

        {/* Table */}
        <table className="tool-table">
        <thead>
            <tr>
              <th className="background-color-white center-image"  colSpan="4">
                <img
                  src={StarBucksLogo}
                  className="img-fluid"
                  alt="Logo"
   
                />
              </th>
            </tr>
            <tr>
                <th className="background-color-white text-center"  colSpan="4">
                    <h4>{getField("tool_header")}</h4>
                </th>
            </tr>
          </thead>

          {/* Company Information */}
          <thead>
            <tr>
              <th>{getField("company")}</th>
              <th>{getField("symbol")}</th>
              <th>{getField("latest")}</th>
              <th>{getField("next_report")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-tool-value">{calculatedValue?.company_value}</td>
              <td className="td-tool-value">{calculatedValue?.symbol_value}</td>
              <td className="td-tool-value">{getField("latest_value")}</td>
              <td className="td-tool-value">{getField("next_report_value")}</td>
            </tr>

            {/* Analyst */}
            <tr>
              <th>{getField("analyst")}</th>
              <th>{getField("shares")}</th>
              <th>{getField("price")}</th>
              <th>{getField("mkt_cap")}</th>
            </tr>
            <tr>
              <td className="exchange-rate-result">{userName}</td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(calculatedValue?.shares_value))}</td>
              <td className="td-tool-value">{calculatedValue?.price_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.price_value)}` : ""}</td>
              <td className="td-tool-value">{calculatedValue?.mkt_cap_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.mkt_cap_value)}` : ""}</td>
            </tr>
          </tbody>

          {/* Section Header */}
          <thead>
            <tr>
              <th colSpan="4" className="background-deep-blue font-white  text-center">
                {getField("lesson_header")}
              </th>
            </tr>
          </thead>

          {/* Valuation Summary */}
          <thead>
            <tr>
              <th>{getField("quarterly_income_statement")}</th>
              <th>{getField("year_ago_label")}</th>
              <th>{getField("baseline_label")}</th>
              <th>{getField("forecast_label")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              
              <th>{getField("total_sales")}</th>
              <td className="td-tool-value">{getField("year_ago")}</td>
              <td className="td-tool-value">{getField("baseline")}</td>
              <td className="td-tool-value">{getField("forecast")}</td>
            </tr>
            <tr>
              <th>{getField("sales")}</th>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.sales_quarter_23)}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.sales_quarter_24)}</td>
              <td className="exchange-rate-result">	{calculatedValue?.sales_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.sales_quarter_27)}` : ""}
              </td>
              

            </tr>
            <tr>
              <th>{getField("growth_rate")}</th>
              <td className="td-tool-value"></td>
              <td className="td-tool-value">{calculatedValue?.growth_rate_quarter_24}%</td>
              <td className="td-tool-value"><input
                className="form-input"
                type="text"
                value={growthRateQuarter}
                onChange={handlePercentageChange(setGrowthRateQuarter)}
                onBlur={handleBlur}
                /></td>

            </tr>
            <tr>
              <th>{getField("cogs")}</th>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.cogs_quarter_23)}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.cogs_quarter_24)}</td>
              <td className="exchange-rate-result">	{calculatedValue?.cogs_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.cogs_quarter_27)}` : ""}
              </td>

            </tr>
            <tr>
              <th>{getField("percent_sales")}</th>
              <td className="td-tool-value">{calculatedValue?.percent_sales_quarter_23}%</td>
              <td className="td-tool-value">{calculatedValue?.percent_sales_quarter_24}%</td>
              <td><input
                className="form-input"
                type="text"
                value={percentSalesQuarter}
                onChange={handlePercentageChange(setPercentSalesQuarter)}
                onBlur={handleBlur}
                /></td>


            </tr>
            <tr>
              <th>{getField("gross_profit")}</th>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.gross_profit_quarter_23)}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(calculatedValue?.gross_profit_quarter_24)}</td>
              <td className="exchange-rate-result">	{calculatedValue?.gross_profit_quarter_27 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.gross_profit_quarter_27)}` : ""}
              </td>
            </tr>
            <tr>
              <th>{getField("gross_margin")}</th>
              <td className="td-tool-value">{calculatedValue?.gross_margin_quarter_23}%</td>
              <td className="td-tool-value">{calculatedValue?.gross_margin_quarter_24}%</td>
              <td className="exchange-rate-result">	{calculatedValue?.gross_margin_quarter_27 !== undefined ? `${calculatedValue?.gross_margin_quarter_27}%` : ""}
                </td>
              
            </tr>
          </tbody>


        </table>
      </div>
    </div>
  );
};

Program3Toolkit3.propTypes = {
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string.isRequired,
          field_value: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

export default Program3Toolkit3;

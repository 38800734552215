import React from "react";
import { useTranslation } from "react-i18next";
import {dashboardShot,learningPath,stepGuidance,prepareForFuture,cashGroup,dollarSignGroup,dollarCoinGroup} from "../../imagepath"; 


const ElevateSkillCategory = () => {
  const { t } = useTranslation();

  return (
    <section className="elevate-skill-category py-5 position-relative" style={{ backgroundColor: "#f8f8f8" }}>
      <div className="container">
        {/* Header and Text */}
        <div className="text-center mb-5">
          <h2>{t("elevateFinancialSkills")}</h2>
          <p>{t("ivotMission")}</p>
        </div>


        <img src={cashGroup} alt="" className="background-small-image cashGroup" style={{zIndex:-1}}/>
        <img src={dollarCoinGroup} alt="" className="background-small-image dollarCoinGroup" style={{zIndex:-1}}/>
        <img src={dollarSignGroup} alt="" className="background-small-image dollarSignGroup" style={{zIndex:-1}} />

        {/* Dashboard Image */}
        <div className="text-center mb-5 ">
          <img src={dashboardShot} alt="Dashboard Screenshot" className="img-fluid w-100"  />
        </div>

        {/* Row of Rectangle Bars */}
        <div className="row">
          {/* Bar 1 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="rectangle-bar d-flex align-items-center">
              <img src={learningPath} alt="Icon 1" className="bar-icon me-3" />
              <p className="bar-text mb-0">{t("customizableLearningPath")}</p>
            </div>
          </div>

          {/* Bar 2 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="rectangle-bar d-flex align-items-center">
              <img src={stepGuidance} alt="Icon 2" className="bar-icon me-3" />
              <p className="bar-text mb-0">{t("stepByStepGuidance")}</p>
            </div>
          </div>

          {/* Bar 3 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="rectangle-bar d-flex align-items-center">
              <img src={prepareForFuture} alt="Icon 3" className="bar-icon me-3" />
              <p className="bar-text mb-0">{t("prepareForYourFuture")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ElevateSkillCategory;

import React, { useState,useEffect } from "react";
import { calculateToolkit3 } from "../../../../../api/programApi";
import {formatNumberWithCommasAndMillions,formatWithDollarAndCommas,stripFormatting} from "../../../../../utils/helper";
import PropTypes from "prop-types";
import "./tool.css";

const Toolkit3 = ({ data }) => {

    const [unitsTwo, setUnitsTwo] = useState(0); 
    const [saleFour, setSaleFour] = useState('0');
    const [calculatedData, setCalculatedData] = useState(null);
 

    useEffect(() => {
      const fetchInitialData = async () => {
        try {
          const response = await calculateToolkit3(unitsTwo, saleFour); 
          if (response.success) {
            setCalculatedData(response.data);
          }
        } catch (error) {
          console.error('Error fetching initial data', error);
        }
      };
  
      fetchInitialData();
    }, [data]);

    const handleSaleFourChange = (e) => {
      setSaleFour(e.target.value); 
    };



  const handleInputBlur = async () => {
      setSaleFour((prev) => formatWithDollarAndCommas(prev));
      const updatedData = await calculateToolkit3(unitsTwo, stripFormatting(saleFour));
      setCalculatedData(updatedData);
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const getField = (fieldName) => {
    const field = data?.data?.find(item => item.field_name === fieldName);
    return field ? field.field_value : '';
  };

  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        {/* Header */}
        <h3 className="background-blue font-white tool-header">
        <i className="fas fa-calculator"></i> {getField('lesson_header')}
        </h3>

        {/* Table */}
        <table className="tool-table">
          <thead>
            <tr>
            
              <th>{getField('company_name')}</th>
              <th colSpan="3">{getField('year')}</th>
              <th colSpan="2">{getField('baseline')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>{getField('day')}</td>
              <td>{getField('unit')}</td>
              <td>{getField('sale')}</td>
              <td>{getField('units')}</td>
              <td>{getField('sales')}</td>
            </tr>

            <tr>
              <td>{getField('avg')}</td>
              <td></td>
              <td>{getField('unit_one')}</td>
              <td>${getField('sale_one')}</td>
              <td>{getField('units_one')}</td>
              <td className="td-tool-value exchange-rate-result">${calculatedData?.sales_one}</td>
            </tr>

            <tr>
              <td>{getField('per_day')}</td>
              <td>{getField('day_two')}</td>
              <td className="td-tool-value exchange-rate-result">{calculatedData?.unit_two}</td>
              <td className="td-tool-value exchange-rate-result">${calculatedData?.sale_two}</td>
              <td>
                      <input
                        className="form-input"
                        type="number"
                        value={unitsTwo}
                        onChange={(e) => setUnitsTwo(e.target.value)}
                        onBlur={handleInputBlur}
                      />
                    </td>
              <td>${calculatedData?.sales_two}</td>
            </tr>

            <tr>
              <td>{getField('per_month')}</td>
              <td>{getField('day_three')}</td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.unit_three)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.sale_three)}</td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.units_three)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.sales_three)}</td>
            </tr>

            <tr>
              <td>{getField('per_quarter')}</td>
              <td>{getField('day_four')}</td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.unit_four)}</td>
              <td>
                      <input
                        className="form-input"
                        type="text"
                        value={saleFour}
                        onChange={handleSaleFourChange}
                        onBlur={handleInputBlur}
                      />
              </td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.units_four)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.sales_four)}</td>
            </tr>

            <tr>
              <td>{getField('per_year')}</td>
              <td>{getField('day_five')}</td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.unit_five)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.sale_five)}</td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.units_five)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.sales_five)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Toolkit3.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Toolkit3;